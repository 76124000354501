// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popUpContainer_Jvbhn{    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    .popUp_zvVwc {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: white;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .popUpHeader_asy0v{
            display: flex;
            align-items: center;
            justify-content: space-between; 
        }
        .popUpBody_Fm7bD{
            display: flex;
            flex-direction: column;
            gap: 16px;
            .popUpEmbed_swlwm{
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
            .popUpViewer_iTV7Y{
                display: flex;
                flex-direction: column;
                gap: 16px;
                b{
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Editor/tool/popUp.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb;QACI,aAAa;QACb,sBAAsB;QACtB,SAAS;QACT,uBAAuB;QACvB,kBAAkB;QAClB,aAAa;QACb,aAAa;QACb,sBAAsB;QACtB,SAAS;QACT;YACI,aAAa;YACb,mBAAmB;YACnB,8BAA8B;QAClC;QACA;YACI,aAAa;YACb,sBAAsB;YACtB,SAAS;YACT;gBACI,aAAa;gBACb,sBAAsB;gBACtB,SAAS;YACb;YACA;gBACI,aAAa;gBACb,sBAAsB;gBACtB,SAAS;gBACT;oBACI,eAAe;oBACf,gBAAgB;gBACpB;YACJ;QACJ;IACJ;AACJ","sourcesContent":[".popUpContainer{    \n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n    .popUp {\n        display: flex;\n        flex-direction: column;\n        gap: 16px;\n        background-color: white;\n        border-radius: 8px;\n        padding: 16px;\n        display: flex;\n        flex-direction: column;\n        gap: 16px;\n        .popUpHeader{\n            display: flex;\n            align-items: center;\n            justify-content: space-between; \n        }\n        .popUpBody{\n            display: flex;\n            flex-direction: column;\n            gap: 16px;\n            .popUpEmbed{\n                display: flex;\n                flex-direction: column;\n                gap: 16px;\n            }\n            .popUpViewer{\n                display: flex;\n                flex-direction: column;\n                gap: 16px;\n                b{\n                    font-size: 16px;\n                    font-weight: 600;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popUpContainer": `popUpContainer_Jvbhn`,
	"popUp": `popUp_zvVwc`,
	"popUpHeader": `popUpHeader_asy0v`,
	"popUpBody": `popUpBody_Fm7bD`,
	"popUpEmbed": `popUpEmbed_swlwm`,
	"popUpViewer": `popUpViewer_iTV7Y`
};
export default ___CSS_LOADER_EXPORT___;
