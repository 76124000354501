import { useState, useEffect } from 'react';
import { IoClose } from "react-icons/io5"; // Asegúrate de tener react-icons instalado

const Input = ({
    // Input props básicas
    label = '',
    value = '',
    onChange = () => {},
    defaultValue = '',
    placeholder = '',
    type = 'text',
    showLabel = true,
    children = null,
    
    // Props de estilo
    className = '',
    labelClassName = '',
    inputClassName = '',
    inputContainerClassName = '',
    
    // Props específicas
    icon = null,
    showClearButton = true,
    min = null,
    max = null, 
    step = 1,
    
    // Props adicionales
    disabled = false,
    required = false,
    onClear = () => {},
}) => {
    const [inputValue, setInputValue] = useState(value || defaultValue);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange?.(e);
    };

    const handleWheel = (e) => {
        if (type === 'number' && document.activeElement === e.target) {
            const delta = e.deltaY > 0 ? -step : step;
            const newValue = Number(inputValue || 0) + delta;
            
            if ((!max || newValue <= max) && (!min || newValue >= min)) {
                setInputValue(newValue);
                onChange?.({ target: { value: newValue } });
            }
        }
    };

    const handleClear = () => {
        setInputValue(defaultValue);
        onClear?.();
        onChange?.({ target: { value: defaultValue } });
    };

    return (
        <div className={`dynamic-input-wrapper ${className}`}>
            {children && <p className="input-title">{children}</p>}
            
            {showLabel && label && (
                <span className={`input-label ${labelClassName}`}>
                    {label}
                </span>
            )}
            
            <div className={`input-container relative ${inputContainerClassName}`}>
                {icon && (
                    <span className="input-icon-left">
                        {icon}
                    </span>
                )}
                
                <input
                    type={type}
                    value={inputValue}
                    onChange={handleChange}
                    onWheel={handleWheel}
                    placeholder={placeholder}
                    className={`
                        input-field
                        ${icon ? 'pl-10' : ''} 
                        ${showClearButton ? 'pr-10' : ''}
                        ${inputClassName}
                    `}
                    min={min}
                    max={max}
                    step={step}
                    disabled={disabled}
                    required={required}
                />
                
                {showClearButton && inputValue && (
                    <button
                        type="button"
                        onClick={handleClear}
                        className="clear-button"
                        aria-label="Clear input"
                    >
                        <IoClose />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Input;




/*

import { FiUser, FiMail, FiDollarSign } from 'react-icons/fi';

// Input de texto básico con icono
<DynamicInput
    label="Nombre de usuario"
    icon={<FiUser />}
    value={username}
    onChange={(e) => setUsername(e.target.value)}
    placeholder="Ingrese su nombre"
>
    Datos personales
</DynamicInput>

// Input numérico con límites
<DynamicInput
    type="number"
    label="Cantidad"
    icon={<FiDollarSign />}
    value={amount}
    onChange={(e) => setAmount(e.target.value)}
    min={0}
    max={1000}
    step={10}
    defaultValue={0}
/>

// Input de email sin label pero con título
<DynamicInput
    icon={<FiMail />}
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    placeholder="ejemplo@correo.com"
    showLabel={false}
>
    Correo electrónico
</DynamicInput>

// Input personalizado con estilos
<DynamicInput
    label="Búsqueda"
    icon={<FiSearch />}
    className="custom-wrapper"
    inputClassName="custom-input"
    labelClassName="custom-label"
    value={search}
    onChange={(e) => setSearch(e.target.value)}
    onClear={() => handleClearSearch()}
/>
*/