import { createSlice } from "@reduxjs/toolkit"

import {
  fetchStore,
  fetchsStore,
  addStore,
  deleteStore,
  updateStore
} from "../actions/store"



export const initialStore = {
  title: ``,
  brand: ``,
  opinion: 0,
  total: 0,
  // rating: 0,
  pegi: '',
  description: ``,
  type: 'app',
  capture: [],
  opinions: [0, 0, 0, 0, 0],
  info: {
      develop: {
          text: 'Microsoft Corporation',
      },
      publy: {
          text: 'Microsoft Corporation',
          link: {
              text: ''
          }
      },
      date: {
          link: {
            text: new Date().toString(),
          }
      },
      category: {
          text: 'Utilidades y herramientas',
          link: {
              text: ''
          },
      },
      size: {
          text: '4,1 MB',
      },
      about: {
          text: 'Obtén esta aplicación mientras tienes iniciadas la sesión en tu cuenta Microsoft e instálatela',
          link: {
              text: 'Mostrar más'
          }
      },
      accesibility: {
          text: 'El desarrollador considera que la aplicación cumple los requisitos de accesibilidad,...',
          link: {
              text: 'Mostrar más'
          }
      },
      apply: {
          text: 'Usa la biblioteca de imágenes',
          link: {
              text: 'Información sobre permisos'
          }
      },
      idiomy: {
          text: 'Sudáfrica..'
      },
      info: {
          text: '',
          link: {
              text: 'Directiva de privacidad de Escáner de Windows'
          }
      },
      condicion: {
          text: '',
          link: {
              text: 'Directiva de privacidad de Escáner de Windows'
          }
      },
      info: {
          text: 'Microsoft Corporation',
          link: {
              text: 'Notificar este producto por infringir la directirva de Microsoft Store'
          }

      },
  }
}






const storeSlice = createSlice({
  name: "store",
  initialState: {
    infoStores: 0,

    stores: [],
    store: null,
  },
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchsStore.fulfilled, (state, action) => {
        state.stores = action.payload.stores
        state.infoStores = action.payload.total
      })
      .addCase(fetchStore.fulfilled, (state, action) => {
        state.store = action.payload
      })

      .addCase(addStore.fulfilled, (state, action) => {
        state.stores.push(action.payload)
      })
      .addCase(deleteStore.fulfilled, (state, action) => {
        const index = state.stores.findIndex((i) => i._id == action.payload)

        let updatedStores = state.stores
        delete updatedStores[index]

        state.stores = updatedStores.filter(Boolean)
      })
      .addCase(updateStore.fulfilled, (state, action) => {
        const index = state.stores.findIndex((i) => i._id == action.payload._id)

        if (index !== -1) {
          let updatedStores = [...state.stores] 
          updatedStores[index] = action.payload

          state.stores = updatedStores
        }
      })
  },
})

export const {  
  setStore,
} = storeSlice.actions

export default storeSlice.reducer
