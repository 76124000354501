// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screenHomeLogin_tT1u9{
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.imageHomeLogin_WYPMN{
    display: flex;
    width: 65%;
    height: 100%;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/LinkeRam/HomeScreen/homeScreen.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".screenHomeLogin{\n    display: flex;\n    width: 100vw;\n    height: 100vh;\n    background-color: white;\n}\n\n.imageHomeLogin{\n    display: flex;\n    width: 65%;\n    height: 100%;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screenHomeLogin": `screenHomeLogin_tT1u9`,
	"imageHomeLogin": `imageHomeLogin_WYPMN`
};
export default ___CSS_LOADER_EXPORT___;
