// RegistrationLink.js
import React from 'react';
import styles from "./loguin.module.css";

const RegistrationLink = () => {
    return (
        <button className={styles.buttonCreate}>
            <div className={styles.inButtonCreate}>
                <p className={styles.p1}>¿No tienes cuenta? </p>
                <p className={styles.p2}>Registrate</p>
            </div>
        </button>
    );
};

export default RegistrationLink;
