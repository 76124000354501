import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

const Input = ({ 
    placeholder = '', 
    value = '', 
    onChange = () => {}, 
    type = "text",
    children = null,
}) => {
    return (
        <div className={`input-container`}>
            {children && <p className="input-title">{children}</p>}
            <input
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    )
}

export default Input


/*
// Ejemplo de uso del componente:
const Presets = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()
    const [inputValue, setInputValue] = useState("")

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div>
            <Input
                label="Nombre"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Escribe tu nombre"
                showLabel={true}
            >
                Información Personal
            </Input>
        </div>
    )
}

export default Presets
*/


/*

// Ejemplo de cómo usar el componente
<Input
    label="Correo electrónico"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    placeholder="ejemplo@correo.com"
    showLabel={true}
>
    Datos de contacto
</Input>

// Sin label
<Input
    value={nombre}
    onChange={(e) => setNombre(e.target.value)}
    placeholder="Escribe tu nombre"
    showLabel={false}
/>

// Con clases personalizadas
<Input
    label="Usuario"
    className="custom-container"
    labelClassName="custom-label"
    inputClassName="custom-input"
    value={usuario}
    onChange={(e) => setUsuario(e.target.value)}
/>

*/