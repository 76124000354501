// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuContainer_sD9Op {
    position: fixed;
    top: 0;
    height: 100vh;
    transition: all 0.3s ease;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .menuLeft_HdRXC {
    left: 0;
  }
  
  .menuRight_svSBb {
    right: 0;
  }
  
  .resizeHandle_DovCN {
    position: absolute;
    top: 0;
    width: 5px;
    height: 100%;
    cursor: col-resize;
  }
  
  .resizeHandle_DovCN:hover {
    background: rgba(0,0,0,0.1);
  }
  
  .resizeHandleLeft_AOlw6 {
    right: 0;
  }
  
  .resizeHandleRight_Ywwqs {
    left: 0;
  }`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Editor/component/menu.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,oCAAoC;EACtC;;EAEA;IACE,OAAO;EACT;;EAEA;IACE,QAAQ;EACV;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,QAAQ;EACV;;EAEA;IACE,OAAO;EACT","sourcesContent":[".menuContainer {\n    position: fixed;\n    top: 0;\n    height: 100vh;\n    transition: all 0.3s ease;\n    overflow: hidden;\n    box-shadow: 0 0 10px rgba(0,0,0,0.1);\n  }\n  \n  .menuLeft {\n    left: 0;\n  }\n  \n  .menuRight {\n    right: 0;\n  }\n  \n  .resizeHandle {\n    position: absolute;\n    top: 0;\n    width: 5px;\n    height: 100%;\n    cursor: col-resize;\n  }\n  \n  .resizeHandle:hover {\n    background: rgba(0,0,0,0.1);\n  }\n  \n  .resizeHandleLeft {\n    right: 0;\n  }\n  \n  .resizeHandleRight {\n    left: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuContainer": `menuContainer_sD9Op`,
	"menuLeft": `menuLeft_HdRXC`,
	"menuRight": `menuRight_svSBb`,
	"resizeHandle": `resizeHandle_DovCN`,
	"resizeHandleLeft": `resizeHandleLeft_AOlw6`,
	"resizeHandleRight": `resizeHandleRight_Ywwqs`
};
export default ___CSS_LOADER_EXPORT___;
