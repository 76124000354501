import React from 'react'

import HomeScreen from './HomeScreen'
import HomeScreen2 from './HomeScreen2'

const Linkeram = () => {
    return (
        <div>
            <HomeScreen2 />
        </div>
    )
}

export default Linkeram