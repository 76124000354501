import React from 'react'
import { useParams } from 'react-router-dom'

import Calendar from './calendar'
import Earn from './earn'
import Event from './event'
import Form from './form'
import Gps from './gps'
import Image from './image'
import Interactive from './interactive'
import LinkButton from './link-button'
import MailChimp from './mailchimp'
import Mp3 from './mp3'
import Music from './music'
import Share from './share'
import Social from './social'
import Video from './video'

const Embed = () => {

    const { subTag } = useParams()

    return (
        <div>
            Embed
            {subTag == 'calendar' ? (
                <Calendar />
            ) : subTag == 'earn' ? (
                <Earn />
            ) : subTag == 'event' ? (
                <Event />
            ) : subTag == 'form' ? (
                <Form />
            ) : subTag == 'gps' ? (
                <Gps />
            ) : subTag == 'image' ? (
                <Image />
            ) : subTag == 'interactive' ? (
                <Interactive />
            ) : subTag == 'link-button' ? (
                <LinkButton />
            ) : subTag == 'mailchimp' ? (
                <MailChimp />
            ) : subTag == 'mp3' ? (
                <Mp3 />
            ) : subTag == 'music' ? (
                <Music />
            ) : subTag == 'share' ? (
                <Share />
            ) : subTag == 'social' ? (
                <Social />
            ) : subTag == 'video' ? (
                <Video />
            ) : null}
        </div>
    )
}

export default Embed