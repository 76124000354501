// import Editor from './Editor'
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import './index.css'

import ComponentMenu from './component/menu'

import Drive from './tool/drive'
import Edit from './tool/edit'
import Insert from './tool/insert'
import WWW from './tool/www'
import Language from './tool/language'
import PopUp from './tool/popUp'


import Pressets from './pressets/assets'
import Settings from './settings'



export const EditorContext = createContext()


const EditorProvider = ({ children }) => {
    const { tag: tagParams, subTag: subTagParams } = useParams()

    const [tag, setTag] = useState(tagParams || '')
    const [subTag, setSubTag] = useState(subTagParams || '')
    const [language, setLanguage] = useState('')

    const value = {
        tag,
        setTag,
        subTag,
        setSubTag,
        language,
        setLanguage
    }

    return (
        <EditorContext.Provider value={value}>
            {children}
        </EditorContext.Provider>
    )
}



const EditorPage = () => {
    return (
        <EditorProvider>
            <EditorContent />
        </EditorProvider>
    )
}

export default EditorPage




const EditorContent = () => {
    const navigate = useNavigate()
    const { tag, setTag, subTag, setSubTag } = useContext(EditorContext)


    const handleClick = () => {
        navigate(`/editor/popUp/calendar`)
        setTag('popUp')
        setSubTag('calendar')
    }

    return (
        <div>
            <div>
                <button onClick={() => setTag('edit')}>Edit</button>
                <button onClick={() => setTag('drive')}>Drive</button>
                <button onClick={() => setTag('insert')}>Insert</button>
                <button onClick={() => setTag('pressets')}>Pressets</button>
                <button onClick={() => setTag('language')}>Language</button>
                <button onClick={() => setTag('settings')}>Settings</button>
                <button onClick={() => setTag('www')}>WWW</button>
                <button onClick={() => handleClick()}>PopUp</button>
            </div>
            TAG: {tag}\
            {tag == 'pressets' ? (
                <ComponentMenu position='right'>
                    <Pressets />
                </ComponentMenu>
            ) : tag == 'drive' ? (
                <ComponentMenu>
                    <Drive />
                </ComponentMenu>
            ) : tag == 'edit' ? (
                <ComponentMenu position='right'>
                    <Edit />
                </ComponentMenu>
            ) : tag == 'insert' ? (
                <ComponentMenu>
                    <Insert />
                </ComponentMenu>
            ) : tag == 'www' ? (
                <ComponentMenu>
                    <WWW />
                </ComponentMenu>
            ) : tag == 'language' ? (
                <ComponentMenu>
                    <Language />
                </ComponentMenu>
            ) : null}

            {(tag == 'popUp' && subTag) ? (
                <PopUp />
            ) : null}
            
            {tag == 'settings' ? (
                <Settings />
            ) : tag == 'edito' ? (
                <div>
                    E.D.I.T.O.R
                </div>
            ) : <Settings />}




            {/* <LanguageParams />
        Empezando editor */}
        </div>
    )
}
/* */






























