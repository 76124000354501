// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
        font-size: 14px;
        font-weight: 500;
    }
    input {
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        padding: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Editor/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT;QACI,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,yBAAyB;QACzB,kBAAkB;QAClB,aAAa;IACjB;AACJ","sourcesContent":[".grid-2{\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 20px;\n}\n.input-container {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    p {\n        font-size: 14px;\n        font-weight: 500;\n    }\n    input {\n        border: 1px solid #E0E0E0;\n        border-radius: 5px;\n        padding: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
