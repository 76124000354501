// SocialButton.js
import React from 'react';
import styles from "./loguin.module.css";


const SocialButton = ({ src, alt, width = 120, height = "auto" }) => {
    return (
        <button className={styles.inputLoguin}>
            <img
                style={{
                    margin: "2% 5%",
                    width: width,
                    height: height,
                    objectFit: "contain",
                }}
                alt={alt}
                src={src}
                height={1000}
                width={1000}
            />
        </button>
    );
};

export default SocialButton;
