import { useContext } from 'react'
import { EditorContext } from '../index'

import styles from './popUp.module.css'

import ComponentEmbed from '../embed'

const PopUp = () => {
    const { setTag } = useContext(EditorContext)

    return (
        <div className={styles.popUpContainer}>
            <div className={styles.popUp}>
                <div className={styles.popUpHeader}>
                    Agrega un titulo
                    icon excel
                    Ver Respuestas

                    icon search
                    icon graph
                    icon group
                    icon close
                </div>
                <div className={styles.popUpBody}>
                    <div className={styles.popUpEmbed}>
                        <ComponentEmbed />
                    </div>
                    <div className={styles.popUpViewer}>
                        <b>
                            Visualizacion
                        </b>
                        <ComponentEmbed />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopUp