import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './form.module.css'

import ComponentExpand from '../component/expand'


const Form = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.form}>
            <ComponentExpand>
                <div>
                    Image
                </div>
            </ComponentExpand>

        </div>
    )
}

export default Form




const Viewer = () => {
    return (
        <div>

            <div>
                <div></div>
                <div>
                    <b>
                        Title layout 
                    </b>
                    <p>
                        This is a Description
                    </p>
                </div>
            </div>
            <div>
                <div>
                    5$
                </div>
                <div>
                    10
                </div>
                <div>
                    20
                </div>
                <button>
                    Donate 5$
                </button>
            </div>

            <div>
                <div>

                </div>
                <div>
                    <b>
                        Title layout 
                    </b>
                    <p>
                        This is a Description
                    </p>
                </div>
                <div>
                    <span>
                        Min
                    </span>
                    <span>
                        Max
                    </span>
                </div>
            </div>



            <div>
                <label>
                    Producto Digital
                </label>
                <label>
                    Archivo
                </label>
                <label>
                    Archivo
                </label>
                <b>
                    Plantillas de Diseño para Redes Socials
                </b>
                <ul>
                    <li>
                        Cantidad:
                        4K HD Estándard
                    </li>
                    <li>
                        Idiomas:
                         Español Ingles
                    </li>
                    <li>
                        2.645 Views
                        425 Downloads
                    </li>
                    <button>
                        Buy 5$
                    </button>
                </ul>
            </div>
        </div>
    )
}