// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeScreen2_uHUPu {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.imageContainer_L0KRj {
    width: 60%; /* Ocupa el 60% del ancho */
    height: 100%; /* Ocupa toda la altura */
}

.containerLoguin_Js1lK {
    display: flex;
    flex-direction: column;
    width: 40%; /* Ocupa el 40% del ancho */
    height: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/LinkeRam/HomeScreen2/homeScreen2.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,UAAU,EAAE,2BAA2B;IACvC,YAAY,EAAE,yBAAyB;AAC3C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU,EAAE,2BAA2B;IACvC,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".homeScreen2 {\n    display: flex;\n    width: 100vw;\n    height: 100vh;\n    background-color: white;\n}\n\n.imageContainer {\n    width: 60%; /* Ocupa el 60% del ancho */\n    height: 100%; /* Ocupa toda la altura */\n}\n\n.containerLoguin {\n    display: flex;\n    flex-direction: column;\n    width: 40%; /* Ocupa el 40% del ancho */\n    height: 100%;\n    background-color: white;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeScreen2": `homeScreen2_uHUPu`,
	"imageContainer": `imageContainer_L0KRj`,
	"containerLoguin": `containerLoguin_Js1lK`
};
export default ___CSS_LOADER_EXPORT___;
