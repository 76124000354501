import { createSlice } from '@reduxjs/toolkit'
import {
	createNewDesktop,
	deleteUserDesktop,
	getUserDesktops,
	updateDesktopSettings,
	updateUserDesktop,
} from '../actions/desktop'
import { getBucketDirectories } from '../actions/assets'
import isEqual from 'lodash/isEqual'

const desktopSlices = createSlice({
	name: 'desktop',
	initialState: {
		selectedDesktop: null,
		desktops: [],
		desktopSettings: {},
		error: null,
		selectedItem: null,
		desktopPopupPath: '',
		selectedCategory: null,
		filteredDirectories: [],
		creatingFolder: false,
		extraElements: [],
	},
	reducers: {
		setUserDesktops: (state, action) => {
			state.desktops = action.payload
		},
		setExtraElements: (state, action) => {
			state.extraElements = action.payload
		},
		setDesktopPopupPath: (state, action) => {
			state.desktopPopupPath = action.payload
		},
		setFilteredDirectories: (state, action) => {
			state.filteredDirectories = action.payload
		},
		setSelectedCategory: (state, action) => {
			state.selectedCategory = action.payload
		},
		setSelectedDesktop: (state, action) => {
			if (action.payload) {
				console.log('Setting selectedDesktop to', action.payload)
				state.selectedDesktop = { ...action.payload }
			}
		},
		setCreatingFolder: (state, action) => {
			state.creatingFolder = action.payload
		},
		setSelectedItem: (state, action) => {
			state.selectedItem = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			// ====================== GET USER DESKTOPS DATA ==========================
			.addCase(getBucketDirectories.fulfilled, (state, action) => {
				console.log('SETTING S3Directories to', action.payload)
				state.S3Directories = action.payload
			})
			.addCase(getUserDesktops.pending, (state) => {
				state.loading = true
			})
			.addCase(getUserDesktops.fulfilled, (state, action) => {
				console.log('DATA from getUserDesktops', action.payload)
				state.loading = false
				state.desktops = action.payload.desktops
				console.log('state.selectedDesktop', state.selectedDesktop)
				if (isEqual(state.desktopSettings, action.payload.settings)) {
					console.log('The settings are the same')
				} else {
					state.desktopSettings = action.payload.settings
				}
				if (!state.selectedDesktop) {
					let defaultDesktop = action.payload.desktops.filter((desktop) =>
						desktop?._id?.includes('default')
					)
					if (defaultDesktop?.length < 1) {
						defaultDesktop = action.payload.desktops
					}
					if (defaultDesktop?.length > 0) {
						state.selectedDesktop = defaultDesktop[0]
					}
				}
			})
			.addCase(getUserDesktops.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			// =======================================================================
			// ===================== UDPATE DESKTOP SETTINGS =========================
			.addCase(updateDesktopSettings.pending, (state) => {
				state.loading = true
			})
			.addCase(updateDesktopSettings.fulfilled, (state, action) => {
				console.log(
					'action.payload received from updateDesktopSettings',
					action.payload
				)
				state.loading = false
				if (action.payload) {
					console.log('Updating settings to..', action.payload.settings)
					state.desktopSettings = action.payload.settings
					if (action.payload.desktops) {
						console.log('Updating user desktops to..', action.payload.desktops)
						state.desktops = action.payload.desktops
						if (state.selectedDesktop) {
							const newDesktopData = action.payload.desktops.filter(
								(desktop) => desktop.id === state.selectedDesktop.id
							)
							if (newDesktopData.length > 0) {
								state.selectedDesktop = { ...newDesktopData[0] }
							}
						}
					}
				}
			})

			.addCase(updateDesktopSettings.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			// =======================================================================
			// ======================= CREATE DESKTOP REQUEST ===========================
			.addCase(createNewDesktop.pending, (state) => {
				state.loading = true
			})
			.addCase(createNewDesktop.fulfilled, (state, action) => {
				state.loading = false
				if (action.payload) {
					console.log('Pushing to desktops..', action.payload)
					state.desktops = [...state.desktops, action.payload]
				}
			})

			.addCase(createNewDesktop.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
			// =======================================================================
			// ====================== UPDATE USER DESKTOP  ==========================
			// .addCase(updateUserDesktop.pending, (state) => {
			// 	state.loading = true
			// })
			// .addCase(updateUserDesktop.fulfilled, (state, action) => {
			// 	state.loading = false
			// 	const index = state.desktops.findIndex(
			// 		(d) => d.id === action.payload.id
			// 	)
			// 	if (index >= 0) {
			// 		console.log('Updating desktop to..', action.payload)
			// 		state.desktops = [
			// 			...state.desktops.slice(0, index),
			// 			action.payload,
			// 			...state.desktops.slice(index + 1),
			// 		]
			// 	}
			// })
			// .addCase(updateUserDesktop.rejected, (state, action) => {
			// 	state.error = action.payload
			// 	state.loading = false
			// })
			// =======================================================================
			// ====================== DELETE USER DESKTOP ==========================
			.addCase(deleteUserDesktop.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteUserDesktop.fulfilled, (state, action) => {
				state.loading = false
				console.log('Removing desktop', action.payload.desktopId)
				state.desktops = [...state.desktops].filter(
					(desktop) => desktop.id !== action.payload.desktopId
				)
			})
			.addCase(deleteUserDesktop.rejected, (state, action) => {
				state.error = action.payload
				state.loading = false
			})
		// =======================================================================
	},
})

export const {
	setUserDesktops,
	setDesktopPopupPath,
	setSelectedDesktop,
	setSelectedItem,
	setFilteredDirectories,
	setSelectedCategory,
	setCreatingFolder,
	setExtraElements,
} = desktopSlices.actions

export default desktopSlices.reducer
