
import react from "react";
import styles from "./createUser.module.css";
import SecurityInfo from "../homeScreen3/SecurityInfo";


export default function CreateUser() {
    return (

            <div className={styles.subContainerLoguin}>
                <img style={{ margin: "40px", width: "45%", height: 'auto', objectFit: "contain", backgroundcolor: "black" }} alt="ojear" src="/formLinkeram.png" height={1000} width={1000}></img>
                <button className={styles.inputLoguin}>
                    <img style={{ margin: "2% 5%", width: "120px", height: 'auto', objectFit: "contain" }} alt="google" src="/google.png" height={1000} width={1000}></img>
                </button>
                <button className={styles.inputLoguin}>
                    <img style={{ margin: "2% 5%", width: "120px", height: 'auto', objectFit: "contain"}} alt="google" src="/google.png" height={1000} width={1000}></img>
                </button>
                   {/* Primer texto */}
            <p className={styles.text}>
            ¿Ya tienes cuenta? <strong className={styles.violetText}>Inicia sesión</strong>
            </p>

            {/* Segundo texto */}
            <p className={styles.text}>
            Creando una cuenta afirmas estar de acuerdo con nuestros  <strong className={styles.violetText}>Términos y Política de Privacidad</strong>
            </p>
                <SecurityInfo></SecurityInfo>

            </div>
    );
}
