import { useContext, useEffect, useState, useRef } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'
import styles from './menu.module.css'

const Menu = ({
  position = 'left',
  background = '#ffffff',
  autoHide = false,
  minWidth = 200,
  maxWidth = 500,
  defaultWidth = 250,
  onVisibilityChange,
  onActiveChange,
  activeSection,
  children
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [width, setWidth] = useState(defaultWidth)
  const [isDragging, setIsDragging] = useState(false)
  const timeoutRef = useRef(null)
  const menuRef = useRef(null)

  useEffect(() => {
    onVisibilityChange?.(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!autoHide) return

    const handleMouseMove = (e) => {
      clearTimeout(timeoutRef.current)
      
      const threshold = 50
      const shouldShow = position === 'left' 
        ? e.clientX < threshold
        : e.clientX > window.innerWidth - threshold

      if (shouldShow) {
        setIsOpen(true)
      } else {
        timeoutRef.current = setTimeout(() => {
          setIsOpen(false)
        }, 3000)
      }
    }

    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [autoHide, position])

  const handleMouseDown = (e) => {
    setIsDragging(true)
    e.preventDefault()
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return

      let newWidth
      if (position === 'left') {
        newWidth = e.clientX
      } else {
        newWidth = window.innerWidth - e.clientX
      }

      newWidth = Math.max(minWidth, Math.min(maxWidth, newWidth))
      setWidth(newWidth)
    }

    const handleMouseUp = () => {
      setIsDragging(false)
    }

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, position, minWidth, maxWidth])

  const menuStyles = {
    background,
    width: isOpen ? `${width}px` : '0px',
    minWidth: isOpen ? `${minWidth}px` : '0px',
    maxWidth: `${maxWidth}px`,
  }


  console.log('chhch!!!!!chc', children)
  if (children == null) return null

  return (
    <div
      ref={menuRef}
      className={`${styles.menuContainer} ${position === 'left' ? styles.menuLeft : styles.menuRight}`}
      style={menuStyles}
    >
      {children}
      <div
        className={`${styles.resizeHandle} ${
          position === 'left' ? styles.resizeHandleLeft : styles.resizeHandleRight
        }`}
        onMouseDown={handleMouseDown}
      />
    </div>
  )
}

export default Menu


/*
<Menu
  position="left"
  background="#f5f5f5"
  autoHide={true}
  minWidth={200}
  maxWidth={500}
  defaultWidth={300}
  onVisibilityChange={(isVisible) => console.log('Menu visible:', isVisible)}
  onActiveChange={(section) => console.log('Active section:', section)}
  activeSection="dashboard"
>
  <div>Contenido del menú</div>
</Menu>
*/