import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './event.module.css'

const Event = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.event}>
            Meet

            Embed

            Pop-Up

            Link

            No estás conectado a Google

            Thumnbail

            Subir imagen

            .jpg


            https://username.linkeram


            icon arrau

            Se ha creado una página de referencia


            Duración de la reunion

            30min

            Zona Horaria

            (GMT+200) Hora de Europa central - Madrid

            Disponibilidad

            Repetir semanalmente


            Lunes  9-00  15:00

            Martes

            Jueves



            Color

            icon play
            Preview






            scheedule meet

            john doe
            icon

            30min
            Google Meet


            Select a day


            Select a time




            Forms

            1:30pm-2:00pm, Thuersday

            Europe / Madrid

            30 mins

            Google Meet

            Invite by email

            info@aythen.com
            info@linkeram.com

        </div>
    )
}

export default Event









const Viewer = () => {
    return (
        <div>

            <div>
                <div>

                </div>
                <div>
                    <b>
                        Shedule a Meeting
                    </b>

                    <div>
                        icon clock 
                        30 mins
                    </div>
                    <div>
                        icon array 
                        AyMeet
                    </div>
                </div>
            </div>
        </div>
    )
}