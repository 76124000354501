import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './video.module.css'

import ComponentExpand from '../component/expand'

const Video = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.video}>

<ComponentExpand>
                <div>
                    Image
                </div>
            </ComponentExpand>
video
URL

https://

El url introducido no es válido

Embed from Youtube, Vimeo, Twitch, etc.

Thailand (4k Uhd) Ex

by Epic Cinematic Music 4 
#4video  scenerical 


or

Video
Subir MP4


Thumbnail
Subir JPG, PNG, SVG

        </div>
    )
}

export default Video



const Viewer = () => {
    return (
        <div>

        <div>
            <b>
                U2- Stay (Fareway, So Close)
            </b>
           <div></div>
           <div>
            <b>
                Title Layout
            </b>
            <p>
                This is a Description
            </p>
           </div>
        </div>
        <div>
        <b>
                U2- Stay (Fareway, So Close)
            </b>
            <div>

            </div>
            <div>
                <b>
                    Title Layout
                </b>
                <p>
                    This is a Description
                </p>
            </div>
        </div>
        </div>
    )
}