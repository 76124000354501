const drive = {
	t1: `Búsqueda rápida`,
	t2: `Crear nuevo`,
	l3: `Nueva carpeta`,
	l4: `Carpeta Standard`,
	l5: `Carpeta Intelligent`,
	l6: `Carpeta Interface`,
	l7: `Carpeta Mega`,
	l8: `Subir archivo`,
	l9: `Subir carpeta`,
	t8: `Mis documentos`,
	t9: `Mis documentos`,
	t10: `Soon`,
	t11: `Prioritarios`,
	t12: `Compartidos`,
	t13: `Recientes`,
	t14: `Destacados`,
	t15: `Papelera`,
	t16: `Papelera`,
	t17: `Un momento...`,
	t18: `Cancelar`,
	t19: `Crear Carpeta`,
	t20: `Crear Escritorio`,
	t21: `Nuevo Escritorio`,

	x1: `Nombre`,
	x2: `Última modificación`,
	x3: `Seleccionar todos`,
	x4: `Quitar todos`,
	x5: `Archivos recientes`,
	x6: `LanceDB`,
	x7: `Aythen DB`,
	x8: `Ver drive`,
	x9: `Inicio`,
	x10: `Nombre`,
	x11: `Nombre`,
	x12: `Tamaño`,
	x13: `Última modificación`,
	x14: `Elementos seleccionados`,
	x15: `Compartido con`,
}

export default drive
