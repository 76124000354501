// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchContainer_p2E52 {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.searchInputWrapper_xGrqS {
    position: relative;
    display: flex;
    align-items: center;
}

.searchIcon_bWeIW {
    position: absolute;
    left: 10px;
    color: #666;
}

.searchInput_lGruR {
    width: 100%;
    padding: 8px 8px 8px 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.searchResults_oIgQC {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    padding: 0;
    list-style: none;
}

.searchResultItem_N0NrN {
    padding: 8px 16px;
    cursor: pointer;
}

.searchResultItem_N0NrN:hover {
    background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Editor/component/search.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".searchContainer {\n    position: relative;\n    width: 100%;\n    max-width: 400px;\n}\n\n.searchInputWrapper {\n    position: relative;\n    display: flex;\n    align-items: center;\n}\n\n.searchIcon {\n    position: absolute;\n    left: 10px;\n    color: #666;\n}\n\n.searchInput {\n    width: 100%;\n    padding: 8px 8px 8px 35px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    font-size: 14px;\n}\n\n.searchResults {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    background: white;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    margin-top: 4px;\n    max-height: 200px;\n    overflow-y: auto;\n    z-index: 1000;\n    padding: 0;\n    list-style: none;\n}\n\n.searchResultItem {\n    padding: 8px 16px;\n    cursor: pointer;\n}\n\n.searchResultItem:hover {\n    background-color: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": `searchContainer_p2E52`,
	"searchInputWrapper": `searchInputWrapper_xGrqS`,
	"searchIcon": `searchIcon_bWeIW`,
	"searchInput": `searchInput_lGruR`,
	"searchResults": `searchResults_oIgQC`,
	"searchResultItem": `searchResultItem_N0NrN`
};
export default ___CSS_LOADER_EXPORT___;
