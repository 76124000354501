import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './earn.module.css'



const Earn = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    const [tag, setTag] = useState('event')

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.edit}>


        </div>
    )
}

export default Earn





const Viewer = () => {
    return (
        <div>

            <div>
                <div></div>
                <div>
                    <b>
                        Subscribe
                    </b>
                    <p>
                        This is a Description
                    </p>
                </div>
            </div>
            <div>
                Enter your email address
                <button>
                    Aceptar
                </button>
            </div>
        </div>
    )
}