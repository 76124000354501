import apiBackend from "@src/apiBackend"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { setModal } from '../slices/iamSlice'

export const addStore = createAsyncThunk(
  "store/addStore",
  async (store, { dispatch }) => {
   
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store",
        {
          store,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      if (error.response.status == 401) {
        throw "Token incorrect gpt"

      } else if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)

export const fetchStore = createAsyncThunk(
  "store/fetchStore",
  async ({ id }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.get(`/store/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
         
        },
      })


      return response.data
    } catch (error) {
      console.log("Error", error)
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      }
    }
  },
)


export const fetchsStore = createAsyncThunk(
  "store/fetchsStore",
  async ({ type, query, offset = 0, limit = 10 }, { dispatch }) => {
    try {
  
      const token = localStorage.getItem("token")
      const response = await apiBackend.get("/store", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          type,
          query,
          offset,
          limit,
        },
      })


      return response.data
    } catch (error) {
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      }else{
        throw "Falta licencia"
      }
    }
  },
)

export const deleteStore = createAsyncThunk(
  "store/deleteStore",
  async ({ id }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.delete("/store", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id,
        },
      })

      return response.data
    } catch (error) {
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)




export const updateStore = createAsyncThunk(
  "store/updateStore",
  async ({ store }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store/update",
        {
          store,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log("Error: ", error.response.data.message)
      throw error.response.data.message
    }
  },
)





export const generateInfo = createAsyncThunk(
  "store/generateInfo",
  async ({ text }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store/generate",
        {
          text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log("Error: ", error.response.data.message)
      throw error.response.data.message
    }
  },
)







export const addOpinionComment = createAsyncThunk(
  "store/addOpinionComment",
  async ({id, commentId, value}, { dispatch }) => {   
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store/comment/opinion",
        {
          id,
          commentId,
          value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      if (error.response.status == 401) {
        throw "Token incorrect gpt"

      } else if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)






export const addOpinionSubComment = createAsyncThunk(
  "store/addOpinionSubComment",
  async ({id, commentId, subCommentId, value}, { dispatch }) => {   
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store/subcomment/opinion",
        {
          id,
          commentId,
          subCommentId,
          value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      if (error.response.status == 401) {
        throw "Token incorrect gpt"

      } else if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)


export const addComment = createAsyncThunk(
  "store/addComment",
  async ({id, comment}, { dispatch }) => {   
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store/comment",
        {
          id,
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log('Error err', error)
      if (error.response.status == 401) {
        throw "Token incorrect gpt"

      } else if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)

export const addSubComment = createAsyncThunk(
  "store/addSubComment",
  async ({id, commentId, subComment}, { dispatch }) => {   
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store/subcomment",
        {
          id,
          commentId,
          subComment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      console.log('Error err', error)
      if (error.response.status == 401) {
        throw "Token incorrect gpt"

      } else if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)

export const fetchComment = createAsyncThunk(
  "store/fetchComment",
  async ({ id }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.get(`/store/comment/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })

      return response.data
    } catch (error) {
      console.log("Error", error)
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      }
    }
  },
)

export const deleteComment = createAsyncThunk(
  "store/deleteComment",
  async ({ id, commentId }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.delete("/store/comment", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id,
          commentId,
        },
      })

      return response.data
    } catch (error) {
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)


export const deleteSubComment = createAsyncThunk(
  "store/deleteSubComment",
  async ({ id, commentId, subCommentId }, { dispatch }) => {
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.delete("/store/subcomment", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id,
          commentId,
          subCommentId,
        },
      })

      return response.data
    } catch (error) {
      if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)



export const exportStore = createAsyncThunk(
  "store/exportStore",
  async (store, { dispatch }) => {
   
    try {
      const token = localStorage.getItem("token")
      const response = await apiBackend.post(
        "/store/export",
        {
          store,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    } catch (error) {
      if (error.response.status == 401) {
        throw "Token incorrect gpt"

      } else if (error.response.status == 400) {
        throw "Ya existe el usuario"
      } else if (error.response.status == 501) {
        throw "Permision denied"
      }
    }
  },
)



export const importStore = createAsyncThunk(
  "store/importStore",
  async ({ files, lng }) => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('files[]', file);
      });
      
      const res = await apiBackend.post(`/store/import`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      return res.data;
    } catch (error) {
      throw error; 
    }
  }
)