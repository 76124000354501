import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './tab.module.css'

const Tab = ({ 
    tabs = [],
    activeTab = '',
    onTabChange = () => {},
    // version = 'v1-1',
    showIcon = false
}) => {
    const { language } = useContext(EditorContext)
    const navigate = useNavigate()

    const handleTabClick = (tab) => {
        onTabChange(tab)
        // navigate(`/${version}/${language}/${tab.path}`)
    }

    return (
        <div className={styles.tabContainer}>
            {tabs.map((tab) => (
                <div
                    key={tab.id}
                    className={`${styles.tabItem} ${activeTab === tab.id ? styles.active : ''}`}
                    onClick={() => handleTabClick(tab)}
                >
                    <span>{tab.text}</span>
                    {showIcon && tab.icon && (
                        <span className={styles.tabIcon}>{tab.icon}</span>
                    )}
                </div>
            ))}
        </div>
    )
}

Tab.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            icon: PropTypes.node
        })
    ).isRequired,
    activeTab: PropTypes.string.isRequired,
    onTabChange: PropTypes.func.isRequired,
    version: PropTypes.string,
    showIcon: PropTypes.bool
}

export default Tab



/*

// Componente padre
const ParentComponent = () => {
    const [activeTab, setActiveTab] = useState('tab1')
    
    const tabs = [
        {
            id: 'tab1',
            text: 'Inicio',
            path: 'home',
            icon: <HomeIcon />
        },
        {
            id: 'tab2',
            text: 'Perfil',
            path: 'profile',
            icon: <UserIcon />
        },
        {
            id: 'tab3',
            text: 'Configuración',
            path: 'settings',
            icon: <SettingsIcon />
        }
    ]

    return (
        <Tab
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={(tab) => setActiveTab(tab.id)}
            showIcon={true}
            version="v1-1"
        />
    )
}

*/
