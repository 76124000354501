import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './form.module.css'

import ComponentExpand from '../component/expand'


const Form = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.form}>
<ComponentExpand>
                <div>
                    Image
                </div>
            </ComponentExpand>
            
        </div>
    )
}

export default Form
