import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './gps.module.css'

import ComponentExpand from '../component/expand'


const GPS = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.gps}>

<ComponentExpand>
                <div>
                    Image
                </div>
            </ComponentExpand>

Map

Url
https://

El Url
introducido no es válido

Embed from Google Maps


        </div>
    )
}

export default GPS
