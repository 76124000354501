import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './music.module.css'

import ComponentExpand from '../component/expand'


const Music = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.music}>
            <ComponentExpand>
                <div>
                    Image
                </div>
            </ComponentExpand>
            Smart links

            Pre-release

            Custom Link


            Url:

            https://

            Embed from Spotify, Apple Music, etc.

            Pop-up

            Embed

            URL
            https://


            Thumbnail

            Subir Imagen
            .jpg

            Titulo
            My MP4

            Artistas
            Jon Doe

            John Doe

            icon Preview

            Color icon arrrow

        </div>
    )
}

export default Music










const Viewer = () => {
    return (
        <div>

            <div>
                <div>

                </div>
                <div>
                    <b>
                        U2- Stay (Fareway, So Close)
                    </b>
                    <span>
                        This is a Description
                    </span>
                    <button>
                        Play
                    </button>
                </div>
            </div>


            <div>
                <div>
                    <div>

                    </div>
                    <div>
                        <b>
                            U2- Stay (Fareway, So Close)
                        </b>
                        <span>
                            This is a Description
                        </span>
                        <button>
                            Play
                        </button>
                    </div>
                </div>
                <div>
                    play
                    <div>
                        bar
                    </div>
                    $$:$$
                </div>
            </div>

            <div>
                <div>
                    <div>

                    </div>
                    <div>
                        <b>
                            Song
                        </b>
                        <span>
                            Artist
                        </span>
                        <button>
                            
                        </button>
                    </div>
                </div>
                <div>
                    play left
                    play next
                    <div>
                        1
                        Song
                        Author
                        2:02
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <div>

                    </div>
                    <div>
                        <b>
                            Song
                        </b>
                        <span>
                            Artist
                        </span>
                        <button>
                            
                        </button>
                    </div>
                </div>
                <div>
                    play left
                    play next
                    <div>
                        1
                        Song
                        Author
                        2:02
                    </div>
                </div>
                <b>
                    SoundCloud
                </b>
            </div>
        </div>
    )
}