import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './edit.module.css'

import ComponentExpand from '../component/expand'
import ComponentTab from '../component/tab'

import EmbedEvent from '../embed/event'
import EmbedForm from '../embed/form'
import EmbedGPS from '../embed/gps'
import EmbedImage from '../embed/image'
import EmbedLinkButton from '../embed/link-button'
import EmbedMusic from '../embed/music'
import EmbedVideo from '../embed/video'


import Input from '../component/inputEditor'

import IconAdvanced from '../assets/edit/icon-advanced.svg'
import IconAliCenter from '../assets/edit/icon-ali-center.svg'
import IconAliLeft from '../assets/edit/icon-ali-left.svg'
import IconAliRight from '../assets/edit/icon-ali-right.svg'
import IconAliVali from '../assets/edit/icon-ali-vali.svg'
import IconBorderOutside from '../assets/edit/icon-border-outside.svg'
import IconClipText from '../assets/edit/icon-clip-text.svg'
import IconComponentSuccess from '../assets/edit/icon-component-success.svg'
import IconComponent from '../assets/edit/icon-component.svg'
import IconEffectLight from '../assets/edit/icon-effect-light.svg'
import IconGrid from '../assets/edit/icon-grid.svg'
import IconLinke from '../assets/edit/icon-linke.svg'
import IconLock from '../assets/edit/icon-lock.svg'
import IconManius from '../assets/edit/icon-minus.svg'
import IconPass from '../assets/edit/icon-pass.svg'
import IconPropDesktop from '../assets/edit/icon-prop-desktop.svg'
import IconPropLaptop from '../assets/edit/icon-prop-laptop.svg'
import IconPropMobile from '../assets/edit/icon-prop-mobile.svg'
import IconPropTablet from '../assets/edit/icon-prop-tablet.svg'
import IconPropWide from '../assets/edit/icon-prop-wide.svg'
import IconRadius from '../assets/edit/icon-radius.svg'
import IconRelation from '../assets/edit/icon-relation.svg'
import IconAdjBottom from '../assets/edit/icon-text-adj-bottom.svg'
import IconAdjCenter from '../assets/edit/icon-text-adj-center.svg'
import IconTextAdjust from '../assets/edit/icon-text-adjust.svg'
import IconTextAuto from '../assets/edit/icon-text-auto.svg'
import IconTextCenter from '../assets/edit/icon-text-center.svg'
import IconTextLeft from '../assets/edit/icon-text-left.svg'
import IconTextLetter from '../assets/edit/icon-text-letter.svg'
import IconTextLine from '../assets/edit/icon-text-line.svg'
import IconTextRight from '../assets/edit/icon-text-right.svg'
import IconTextTop from '../assets/edit/icon-text-top.svg'
import IconValiBottom from '../assets/edit/icon-vali-bottom.svg'
import IconValiCenter from '../assets/edit/icon-vali-center.svg'
import IconValiTop from '../assets/edit/icon-vali-top.svg'
import IconViewOff from '../assets/edit/icon-view-off.svg'
import IconView from '../assets/edit/icon-view.svg'
import IconVisual from '../assets/edit/icon-visual.svg'

const Edit = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    const [tag, setTag] = useState('event')

    const tabs = [
        {
            id: 'tab1',
            text: 'Inicio',
            path: 'home',
            icon: <IconAdvanced />
        }, {
            id: 'tab2',
            text: 'Perfil',
            path: 'profile',
            icon: <IconAliCenter />
        }, {
            id: 'tab3',
            text: 'Configuración',
            path: 'settings',
            icon: <IconAliLeft />
        }
    ]



    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.edit}>

            <button onClick={() => setTag('event')}>Event</button>
            <button onClick={() => setTag('form')}>Form</button>
            <button onClick={() => setTag('gps')}>GPS</button>
            <button onClick={() => setTag('image')}>Image</button>
            <button onClick={() => setTag('link-button')}>Link button</button>
            <button onClick={() => setTag('music')}>Music</button>
            <button onClick={() => setTag('video')}>Video</button>

            ------------------

            <ComponentTab>

                <div>
                    visual

                    Advanced
                </div>
            </ComponentTab>
            ------------------
            {tag == 'event' ? (
                <EmbedEvent />
            ) : tag == 'form' ? (
                <EmbedForm />
            ) : tag == 'gps' ? (
                <EmbedGPS />
            ) : tag == 'image' ? (
                <EmbedImage />
            ) : tag == 'link-button' ? (
                <EmbedLinkButton />
            ) : tag == 'music' ? (
                <EmbedMusic />
            ) : tag == 'video' ? (
                <EmbedVideo />
            ) : null}

            <ComponentExpand>
                Alineaciones

                1
                234
            </ComponentExpand>
            <ComponentExpand>
                Propiedades

                <Input
                    label="Nombre de usuario"
                    // icon={<IconPropDesktop />}
                    value={'eeeee'}
                    onChange={(e) => console.log(e.target.value)}
                    placeholder="Ingrese su nombre"
                >
                    Datos personales
                </Input>
            </ComponentExpand>

            x
            72


            y
            72


            w
            72
            h
            72


            radio
            0º

            2
            radio

            Clip content
            <ComponentExpand>
                Text
                icon graap

                Roboto

                Medium 10001

                Auto

                0%

                Auto
            </ComponentExpand>
            <ComponentExpand>
                Icons
                1234
            </ComponentExpand>
            <ComponentExpand>
                Size

                Width
                Height
                Min W
                Min H
                Max W
                Min W
            </ComponentExpand>
            <ComponentExpand>
                Unit of length
                px

                Flex

                initial
            </ComponentExpand>
            <ComponentExpand>
                icon
                Component

                icon share
                icon array
            </ComponentExpand>
            <ComponentExpand>
                Margin icon arrya


                0

                0

                0

                0


                Unit of length
                px
            </ComponentExpand>
            <ComponentExpand>
                Padding
                icon array



                0


                0


                0

                0


                Unit of length22
            </ComponentExpand>
            <ComponentExpand>
                Constrains

                Unit of length
            </ComponentExpand>
            <ComponentExpand>
                Image

                src
                alt
                loading

                Layout Grid
                Grid 10px
            </ComponentExpand>
            <ComponentExpand>
                Layer
                Pass Trought
                100%
                icon eye
            </ComponentExpand>
            <ComponentExpand>
                Fill
                background red

                #A8000
                100%
            </ComponentExpand>
            <ComponentExpand>
                Border
                #F2F2F2
                100%
            </ComponentExpand>
            <ComponentExpand>
                Selections Colors
                grey/g900
                grey/g900
            </ComponentExpand>
            <ComponentExpand>

                Effects
                icon array
                icon plus

                icon array
            </ComponentExpand>

            <ComponentExpand>
                Background Blur
                icon array
                icon eye
            </ComponentExpand>


            <ComponentExpand>
                Export

                16px

                16px

                Suffix

                SVG

                icon array
            </ComponentExpand>
            <ComponentExpand>
                Advanced

                Alineaciones

                Attributos
                Tag

                ID
                ID
                custom attr
                value
            </ComponentExpand>
            <ComponentExpand>
                States er

                Classes er

                Text Styles

                Properties

                Poner lista de propiedades...
            </ComponentExpand>
            <ComponentExpand>
                Media query 1600px

            </ComponentExpand>
            <ComponentExpand>
                1200px


            </ComponentExpand>
            <ComponentExpand>
                991px

            </ComponentExpand>
            <ComponentExpand>
                767px


            </ComponentExpand>
            <ComponentExpand>
                479px

            </ComponentExpand>



        </div>
    )
}

export default Edit
