import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import ComponentDrag from '../component/drag'
import ComponentSearch from '../component/search'
import ComponentExpand from '../component/expand'

import styles from './insert.module.css'


import { ReactComponent as IconApple } from "../assets/insert/apple.svg"
import { ReactComponent as IconAudio } from "../assets/insert/audio.svg"
import { ReactComponent as IconCard } from "../assets/insert/card.svg"
import { ReactComponent as IconCarouselSlider } from "../assets/insert/carousel-slider.svg"
import { ReactComponent as IconContainer } from "../assets/insert/container.svg"
import { ReactComponent as IconDonations } from "../assets/insert/donations.svg"
import { ReactComponent as IconDownloadButton } from "../assets/insert/download-button.svg"
import { ReactComponent as IconEmbed } from "../assets/insert/embed.svg"
import { ReactComponent as IconEvents } from "../assets/insert/events.svg"
import { ReactComponent as IconForms } from "../assets/insert/forms.svg"
import { ReactComponent as IconGif } from "../assets/insert/gif.svg"
import { ReactComponent as IconGrid } from "../assets/insert/grid.svg"
import { ReactComponent as IconHeader } from "../assets/insert/header.svg"
import { ReactComponent as IconIcon } from "../assets/insert/icon.svg"
import { ReactComponent as IconIcons } from "../assets/insert/icons.svg"
import { ReactComponent as IconIframe } from "../assets/insert/iframe.svg"
import { ReactComponent as IconImageIcons } from "../assets/insert/image-icons.svg"
import { ReactComponent as IconLayout } from "../assets/insert/layout.svg"
import { ReactComponent as IconLinkProduct } from "../assets/insert/link-product.svg"
import { ReactComponent as IconLottie } from "../assets/insert/lottie.svg"
import { ReactComponent as IconMailchimp } from "../assets/insert/mailchimp.svg"
import { ReactComponent as IconMaps } from "../assets/insert/maps.svg"
import { ReactComponent as IconMeet } from "../assets/insert/meet.svg"
import { ReactComponent as IconMusic } from "../assets/insert/music.svg"
import { ReactComponent as IconParagraph } from "../assets/insert/paragraph.svg"
import { ReactComponent as IconPlay } from "../assets/insert/play.svg"
import { ReactComponent as IconPopUp } from "../assets/insert/pop-up.svg"
import { ReactComponent as IconRow } from "../assets/insert/row.svg"
import { ReactComponent as IconSchedule } from "../assets/insert/schedule.svg"
import { ReactComponent as IconSeparator } from "../assets/insert/separator.svg"
import { ReactComponent as IconSpotify } from "../assets/insert/spotify.svg"
import { ReactComponent as IconText } from "../assets/insert/text.svg"
import { ReactComponent as IconVimeo } from "../assets/insert/vimeo.svg"
import { ReactComponent as IconWhatsapp } from "../assets/insert/whatsapp.svg"
import { ReactComponent as IconYoutube } from "../assets/insert/youtube.svg"



const Insert = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])


    const [ items, setItems ] = useState(
        {
            'tipograpy': {
                title: 'Tipografía',
                component: [{
                    icon: 'icon search',
                    text: 'text'
                },{
                    icon: 'icon search',
                    text: 'header'
                },{
                    icon: 'icon search',
                    text: 'paragraph'
                }]
            },
            'layout': {
                title: 'Layout',
                component: [{
                    icon: 'icon search',
                    text: 'Container'
                },{
                    icon: 'icon search',
                    text: 'Grid'
                },{
                    icon: 'icon search',
                    text: 'Card'
                },{
                    icon: 'icon search',
                    text: 'Row (R)'
                },{
                    icon: 'icon search',
                    text: 'Separator'
                },{
                    icon: 'icon search',
                    text: 'Carousel-Slider'
                }]
            },
            'earn': {
                title: 'Earn',
                component: [{
                    icon: 'icon search',
                    text: 'Donations'
                },{
                    icon: 'icon search',
                    text: 'Service'
                },{
                    icon: 'icon search',
                    text: 'Digital Product'
                },{
                    icon: 'icon search',
                    text: 'Link Product'
                }]
            },
            'audio': {
                title: 'Audio',
                component: [{
                    icon: 'icon search',
                    text: 'Music'
                },{
                    icon: 'icon search',
                    text: 'Spotify'
                },{
                    icon: 'icon search',
                    text: 'SoundCloud'
                },{
                    icon: 'icon search',
                    text: 'MP3 Player'
                }]
            },
            'video': {
                title: 'Video',
                component: [{
                    icon: 'icon search',
                    text: 'MP4'
                },{
                    icon: 'icon search',
                    text: 'Youtube',
                },{
                    icon: 'icon search',
                    text: 'Vimeo',
                }]
            },
            'image': {
                title: 'Image',
                component: [{
                    icon: 'icon search',
                    text: 'Image',
                },{
                    icon: 'icon search',
                    text: 'Icons',
                }]
            },
            'animation': {
                title: 'Animation',
                component: [{
                    icon: 'icon search',
                    text: 'Giff',
                },{
                    icon: 'icon search',
                    text: 'Lottie',
                }]
            },
            'calendar': {
                title: 'Calendar',
                component: [{
                    icon: 'icon search',
                    text: 'Reservas',
                },{
                    icon: 'icon search',
                    text: 'Eventos',
                },{
                    icon: 'icon search',
                    text: 'Aythen',
                }]
            },
            'forms': {
                title: 'Forms',
                component: [{
                    icon: 'icon search',
                    text: 'Form',
                },{
                    icon: 'icon search',
                    text: 'Mailchimp',
                },{
                    icon: 'icon search',
                    text: 'Contact Form',
                }]
            },
            'gps': {
                title: 'GPS',
                component: [{
                    icon: 'icon search',
                    text: 'Maps',
                }]
            },
            'tools': {
                title: 'Tools',
                component: [{
                    icon: 'icon search',
                    text: 'Embed',
                },{
                    icon: 'icon search',
                    text: 'Copy Clipboard',
                },{
                    icon: 'icon search',
                    text: 'Download Button',
                },{
                    icon: 'icon search',
                    text: 'Link Button',
                },{
                    icon: 'icon search',
                    text: 'Iframe',
                },{
                    icon: 'icon search',
                    text: 'Pop-up',
                }]
            },
            'social': {
                title: 'Social',
                component: [{
                    icon: 'icon search',
                    text: 'Whatsapp',
                }, {
                    icon: 'icon search',
                    text: 'Youtube',
                }, {
                    icon: 'icon search',
                    text: 'Instagram',
                }]
            }
        }
    )

    return (
        <div className={styles.insert}>
        
           <ComponentSearch />

            {/* <ComponentExpand /> */}

            {/* <ComponentGrid /> */}

           icon search
           search
           icon arrow

           

           

           
        {Object.keys(items).map((key, index) => (
            <ComponentExpand key={index}>
                ---:
                {key}
                {items[key].component.map((item, index) => (
                    <div key={index}>
                        <ComponentDrag>
                        {item.text}
                        </ComponentDrag>
                    </div>
                ))}
            </ComponentExpand>
        ))}

           



           layout
           


           Grid
           Card
           Row
           Separator
           Carousel-Slider




           
           Product
           Link Product
           

           Earn

           Your Product
           Donations

           Audio

           Music
           Apple Podcast
           Spotify

           Video
           MP4
           Yooutube2

           Vimeo

           Image
           Image
           Icons
           2


           Animation

           Giff

           Lottie


           Calendar
           Reservas
           Eventos

           Meet

           Forms

           Forms
           Mailchimp


           Maps

           Maps



           Tools
           Embed
           Copy Clipboard
           Download Button
           Link Button
           Iframe
           Pop-up
           Social
           Whatsapp
        </div>
    )
}

export default Insert