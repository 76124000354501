import { useState } from 'react'
import styles from './expand.module.css'

const ExpandableMenu = ({ 
    icon = null,
    title = '', 
    children = null, 
    defaultExpanded = false 
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded)

    return (
        <div className={styles.menuContainer}>
            <div 
                className={styles.menuHeader}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {icon && (
                    <div className={styles.menuIcon}>
                        {icon}
                </div>
                )}
                <span className={styles.title}>{title}</span>
                <span className={`${styles.arrow} ${isExpanded ? styles.arrowExpanded : ''}`}>
                    ▼
                </span>
            </div>
            <div className={`${styles.contentWrapper} ${isExpanded ? styles.contentExpanded : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default ExpandableMenu


/*
<ExpandableMenu title="Configuración">
    <p>Contenido del menú expandible</p>
    <button>Algún botón</button>
</ExpandableMenu>
*/