// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_GHVNG{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .buttons_iYjkv{
        display: flex;
        gap: 10px;
        button{
            padding: 10px;
            border-radius: 5px;
        }
    }
    .bar_xjlTK{
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
        ul{
            display: inline-flex;
            flex-direction: column;
            gap: 10px;
            li{
                background: red;
                padding: 10px;
                border-radius: 5px;
            }
        }
    }
}
`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Ram/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT;QACI,aAAa;QACb,SAAS;QACT;YACI,aAAa;YACb,kBAAkB;QACtB;IACJ;IACA;QACI,oBAAoB;QACpB,sBAAsB;QACtB,SAAS;QACT;YACI,oBAAoB;YACpB,sBAAsB;YACtB,SAAS;YACT;gBACI,eAAe;gBACf,aAAa;gBACb,kBAAkB;YACtB;QACJ;IACJ;AACJ","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    .buttons{\n        display: flex;\n        gap: 10px;\n        button{\n            padding: 10px;\n            border-radius: 5px;\n        }\n    }\n    .bar{\n        display: inline-flex;\n        flex-direction: column;\n        gap: 10px;\n        ul{\n            display: inline-flex;\n            flex-direction: column;\n            gap: 10px;\n            li{\n                background: red;\n                padding: 10px;\n                border-radius: 5px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_GHVNG`,
	"buttons": `buttons_iYjkv`,
	"bar": `bar_xjlTK`
};
export default ___CSS_LOADER_EXPORT___;
