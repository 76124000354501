import React, { useState } from 'react'
import styles from './index.module.css'


const initialActions = [
    {
        date: new Date(),
        action: 'action 1',
        api: 'api 1',
    }
]

const Ram = () => {


    const [actions, setActions] = useState([{
        date: new Date(),
        action: 'action 1',
        api: 'api 1',
    }])




    return (
        <div className={styles.container}>
            <div className={styles.buttons}>
                <button>
                    +
                </button>
                <button>
                    -
                </button>
            </div>
            <div className={styles.bar}>
                <ul>
                    <li>
                        {[""].map((item, index) => (
                            <div className={styles.item}>
                                #api{index + 1}
                            </div>
                        ))}
                    </li>
                    <li>
                        #api2
                    </li>
                    <li>

                    </li>
                    <li>

                    </li>
                    <li>

                    </li>
                </ul>
            </div>
        </div>)
}

export default Ram
1