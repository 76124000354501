import { useContext, useEffect, useState, useRef } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

// const languages = [
//     { id: 'javascript', name: 'JavaScript' },
//     { id: 'python', name: 'Python' },
//     { id: 'java', name: 'Java' },
//     // Puedes agregar más lenguajes aquí
// ]

const Select = ({
    options = [],
    isOpen = false,
    setIsOpen = () => {},
    selected = '',
    setSelected = () => {}
}) => {
    // const params = useParams()
    // const [isOpen, setIsOpen] = useState(false)
    const selectRef = useRef(null)


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const handleSelect = (langId) => {
        setLanguage(langId)
        setIsOpen(false)
    }

    return (
        <div className="custom-select" ref={selectRef}>
            <div 
                className="select-input"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{options.find(option => option.id === slected)?.name || 'Select txt'}</span>
                <span className={`arrow ${isOpen ? 'open' : ''}`}>▼</span>
            </div>
            {isOpen && (
                <ul className="select-options">
                    {options.map(option => (
                        <li 
                            key={option.id}
                            onClick={() => handleSelect(option.id)}
                            className={selected === option.id ? 'selected' : ''}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Select