import React, { useState } from 'react';
import styles from "./loguin.module.css";

const PasswordInput = ({ placeholder, className }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={styles.cajaInputClave}>
        <input className={styles.inputLoguin} placeholder={"Contraseña"} />

            <img
                onClick={togglePasswordVisibility}
                style={{
                    top: 13,
                    right: 10,
                    position: "absolute",
                    width: "18px",
                    height: "12px",
                    objectFit: "contain"
                }}
                alt="ojear"
                src="/ojear.png"
                height={1000}
                width={1000}
            />
        </div>
    );
};

export default PasswordInput;
