import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import { ReactComponent as IconDate } from "../assets/link/icon-date.svg"
import { ReactComponent as IconEdit } from "../assets/link/icon-edit.svg"
import { ReactComponent as IconEmbed1 } from "../assets/link/icon-embed-1.svg"
import { ReactComponent as IconEmbed2 } from "../assets/link/icon-embed-2.svg"
import { ReactComponent as IconEmbed3 } from "../assets/link/icon-embed-3.svg"
import { ReactComponent as IconEmbed4 } from "../assets/link/icon-embed-4.svg"
import { ReactComponent as IconEmbed } from "../assets/link/icon-embed.svg"
import { ReactComponent as IconForm } from "../assets/link/icon-form.svg"
import { ReactComponent as IconLinkButton1 } from "../assets/link/icon-link-button-1.svg"
import { ReactComponent as IconLinkButton2 } from "../assets/link/icon-link-button-2.svg"
import { ReactComponent as IconLinkButton3 } from "../assets/link/icon-link-button-3.svg"
import { ReactComponent as IconLinkButton4 } from "../assets/link/icon-link-button-4.svg"
import { ReactComponent as IconLinkButton5 } from "../assets/link/icon-link-button-5.svg"
import { ReactComponent as IconLinkButton6 } from "../assets/link/icon-link-button-6.svg"
import { ReactComponent as IconLinkButton7 } from "../assets/link/icon-link-button-7.svg"
import { ReactComponent as IconList } from "../assets/link/icon-list.svg"
import { ReactComponent as IconMusic1 } from "../assets/link/icon-music-1.svg"
import { ReactComponent as IconMusic2 } from "../assets/link/icon-music-2.svg"
import { ReactComponent as IconMusic3 } from "../assets/link/icon-music-3.svg"
import { ReactComponent as IconSong } from "../assets/link/icon-song.svg"


import Search from '../component/search'

import ComponentExpand from '../component/expand'

const Pressets = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])


    //-------------------------------------------

    const [selectedItem, setSelectedItem] = useState(null)

    // Función de búsqueda que retorna resultados
    const handleSearch = async (searchTerm) => {
        // Aquí irían tus llamadas a la API o lógica de búsqueda
        const response = await fetch(`/api/search?q=${searchTerm}`)
        const data = await response.json()
        return data
    }

    // Función que se ejecuta cuando se selecciona un item
    const handleSelect = (item) => {
        setSelectedItem(item)
        // Aquí puedes agregar más lógica
    }


    return (
        <div>
            <Search
                onSearch={handleSearch}
                onSelect={handleSelect}
                placeholder="Buscar elementos..."
                showList={true}
                delay={700}
            />

            icon assets
            icon color
            icon famoly
            icon txt
            icon css

            search folder

            {[""].map((item, index) => (
                <ComponentExpand
                    title=" Pressets del proyecto"
                >

                    {[""].map((item, index) => (
                        <div>
                            Assets #1

                            <div>
                                icon cog
                                icon arrow
                            </div>
                        </div>
                    ))}

                </ComponentExpand>
            ))}





            <div>
                Colores guardados
                A-Z
                icon arrow
                icon plus
            </div>


            <ComponentExpand
                title="Colores guardados"
            >
                <div>
                    icon cog
                    icon arrow
                </div>
            </ComponentExpand>

            <div>
                <ul>
                    {["", ""].map((item, index) => (
                        <li>
                            color
                        </li>
                    ))}
                </ul>
            </div>


            <ComponentExpand
                title="Colores guardados"
            >
                <div>
                    icon cog
                    icon arrow
                </div>
            </ComponentExpand>

            <div>
                Tipografías guardada

                A-Z
            </div>
            <div>
                <ul>
                    {["", ""].map((item, index) => (
                        <li>
                            Aa

                            Content
                            12px inenlight

                            Default

                            icon drag
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                New Layout

                A-Z
            </div>

            <ComponentExpand
                title="New Layout"
            >
                <div>
                    Size

                    +

                    arr
                </div>
            </ComponentExpand>
            <div>
                <ul>
                    {["", ""].map((item, index) => (
                        <li>
                            xs
                            sm
                            md
                            lg
                            xl
                            xxl
                        </li>
                    ))}
                </ul>
            </div>

            space

            half
            8px

            one 16px

        </div>
    )
}

export default Pressets
