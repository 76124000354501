import React from 'react';
import styles from "./loguin.module.css";


const GoButton = ({ label }) => {
    return (
        <button className={styles.buttonInit}>{label}</button>
    );
};

export default GoButton;
