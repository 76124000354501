import React from 'react';

const LogoImage = ({ src, alt, width = "50%" }) => {
    return (
        <img
            style={{
                margin: "40px",
                width: width,
                height: "auto",
                objectFit: "contain"
            }}
            alt={alt}
            src={src}
        />
    );
};

export default LogoImage;
