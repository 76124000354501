import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"


import { EditorContext } from '../index'

import { initialLanguage } from '../assets/initial/language'


const Language = () => {
    const { language, setLanguage, setTag } = useContext(EditorContext)
    const params = useParams()

    // useEffect(() => {
    //     if (params.language) {
    //         setLanguage(params.language)
    //     }
    // }, [params.language])

    const handleChangeLanguage = (language) => {
        setLanguage(language)
        setTag(null)
    }

    return (
        <div>
            {initialLanguage.map((item, index) => (
                <div key={index} onClick={() => handleChangeLanguage(item.title)}>
                    <div>
                        icon bandera
                        <img src={item.icon} />
                    </div>
                    <span>
                        Current language: {item.title}
                    </span>
                </div>
            ))}
        </div>
    )
}


export default Language
