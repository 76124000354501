// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuContainer_VnExZ {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 8px 0;
    overflow: hidden;
}

.menuHeader_nyY8N {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #f5f5f5;
    cursor: pointer;
    transition: background-color 0.2s;
}

.menuHeader_nyY8N:hover {
    background-color: #eeeeee;
}

.title_O4dm9 {
    font-weight: 500;
}

.arrow_V6REx {
    transition: transform 0.3s ease;
}

.arrowExpanded_MKatR {
    transform: rotate(180deg);
}

.contentWrapper_WCUBr {
    padding: 0 16px;
    max-height: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.contentExpanded_drDjp {
    max-height: 500px;
    opacity: 1;
    padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Editor/component/expand.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,UAAU;IACV,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,aAAa;AACjB","sourcesContent":[".menuContainer {\n    border: 1px solid #e0e0e0;\n    border-radius: 8px;\n    margin: 8px 0;\n    overflow: hidden;\n}\n\n.menuHeader {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 12px 16px;\n    background-color: #f5f5f5;\n    cursor: pointer;\n    transition: background-color 0.2s;\n}\n\n.menuHeader:hover {\n    background-color: #eeeeee;\n}\n\n.title {\n    font-weight: 500;\n}\n\n.arrow {\n    transition: transform 0.3s ease;\n}\n\n.arrowExpanded {\n    transform: rotate(180deg);\n}\n\n.contentWrapper {\n    padding: 0 16px;\n    max-height: 0;\n    opacity: 0;\n    transition: all 0.3s ease-in-out;\n    overflow: hidden;\n}\n\n.contentExpanded {\n    max-height: 500px;\n    opacity: 1;\n    padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuContainer": `menuContainer_VnExZ`,
	"menuHeader": `menuHeader_nyY8N`,
	"title": `title_O4dm9`,
	"arrow": `arrow_V6REx`,
	"arrowExpanded": `arrowExpanded_MKatR`,
	"contentWrapper": `contentWrapper_WCUBr`,
	"contentExpanded": `contentExpanded_drDjp`
};
export default ___CSS_LOADER_EXPORT___;
