
import react from "react";
import styles from "./homeScreen.module.css";
import Loguin from "../component/homeScreen/Loguin";

export default function HomeScreen() {
    return (
        <div className={styles.screenHomeLogin}>
            {//izquierda
            }
            <div className={styles.imageHomeLogin}>
                <img style={{ width: "100%", height: 'auto', objectFit: "cover" }} alt="foto" src="/fondoLinkeram.png" height={1000} width={1000}></img>
            </div>
                {//derecha
            }
            <Loguin></Loguin>
        </div>
    );
}
