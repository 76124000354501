import React from "react";

export default function SecurityInfo() {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 5, justifyContent: "center", paddingBottom: 30 }}>
      <img alt="#" src="/Lock.png" style={{ width: 14, height: 14 }} />
      <p style={{ fontSize: 12, color: "#1A1A1A" }}>Tu seguridad nos importa</p>
    </div>
  );
}
