import { useContext, useEffect, useState } from 'react'
import { EditorContext } from './index'
import { useParams } from 'react-router-dom'

import styles from './settings.module.css'

import Input from './component/input'
import Select from './component/select'

import { ReactComponent as IconLock } from "./assets/settings/icon-lock.svg"
import { ReactComponent as IconTranslate } from "./assets/settings/icon-translate.svg"
import { ReactComponent as IconStar } from "./assets/settings/icon-star.svg"
import { ReactComponent as IconUser } from "./assets/settings/icon-user.svg"
import { ReactComponent as IconLink } from "./assets/settings/icon-link.svg"
import { ReactComponent as IconSuccess } from "./assets/settings/icon-success.svg"

import { ReactComponent as IconHome } from "./assets/settings/icon-home.svg"

import { ReactComponent as IconWorld } from "./assets/settings/icon-world.svg"
import { ReactComponent as IconWorldSearch } from "./assets/settings/icon-world-search.svg"

import { ReactComponent as IconImportant } from "./assets/settings/icon-important.svg"
import { ReactComponent as IconEdit } from "./assets/settings/icon-edit.svg"

import { ReactComponent as IconFacebook } from "./assets/settings/icon-facebook.svg"
import { ReactComponent as IconGoogle } from "./assets/settings/icon-google.svg"

import { ReactComponent as IconAlert } from "./assets/settings/icon-alert.svg"

const Settings = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])


    const [input, setInput] = useState("")

    return (
        <div className={styles.settings}>
            <div className={styles.menu}>
                <label className={styles.title}>
                    Site Settings
                </label>
                <ul>
                    <li>General</li>
                    <li>Collaborators</li>
                    <li>Cookies</li>
                    <li>Dominios</li>
                    <li>Favicon</li>
                    <li>SEO</li>
                    <li>Pixels</li>
                    <li>Publish</li>
                </ul>

                <label className={styles.title}>
                    Page Settings
                </label>
                <ul>
                    <li>
                        Home
                    </li>
                    <li>
                        /new-folder
                    </li>
                    <li>
                        /blog
                    </li>
                    <li>
                        /categories
                    </li>
                    <li>
                        /404
                    </li>
                </ul>
            </div>

            <div className={styles.content}>
                <h2 className={styles.title}>
                    Settings
                </h2>
                <div className={styles.header}>
                    <div className={styles.avatar}>
                        A
                    </div>
                    <span>
                        by
                        Aythen
                    </span>
                    <div className={styles.iconLock}>
                        <IconLock />
                        Private
                        Public
                    </div>
                    <div className={styles.iconStar}>
                        Not rated
                    </div>
                    <div className={styles.iconUser}>
                        Used by 123 people
                    </div>
                </div>

                <div className={styles.general}>
                    <div className={`grid-2`}>
                        <Input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Ingrese su nombre"
                        >
                            My Linkeram Site
                        </Input>
                        <Input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Ingrese su nombre"
                        >
                            Untitled
                        </Input>
                    </div>
                    <div className={`grid-2`}>
                        <Input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Publico"
                        >
                            Status
                        </Input>
                        <Input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Selecciona Categoría"
                        >
                            Categoría de Negocios
                        </Input>
                    </div>
                    <Input
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="username.linkeram.com/"
                    >
                        Base Domain
                    </Input>
                    See Domains
                    <Input
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Made with Linkeram"
                    >
                        Descripción
                    </Input>
                </div>

                <div className={styles.preview}>
                    <b className={styles.title}>
                        Preview
                    </b>
                    <div className={styles.banner}>
                        <span>
                            yourdomain.url
                        </span>
                        <b>
                            My Linkeram Site
                        </b>
                        <p>
                            Made with Linkeram
                        </p>
                    </div>
                    <div className={styles.check}>
                        check
                        Eliminar marca de agua
                        <label>
                            PRO
                        </label>
                    </div>
                </div>
                <div className={styles.collaborators}>
                    <b className={styles.title}>
                        Collaborators
                    </b>
                    <div className={styles.input}>
                        <div className={styles.inputEmail}>
                            <input
                                type="email"
                                placeholder="name@email.com"
                            />
                            <div>
                                All Permissions
                            </div>
                        </div>
                        <button>
                            Send
                        </button>
                    </div>
                    <ul>
                        {[""].map((item, index) => (
                        <li>
                            <div>
                                M
                            </div>
                            <div>
                                <b>
                                    My Workspace
                                    icon success
                                </b>
                                <span>
                                    All members can access
                                    Anyone with link can view
                                    Owner
                                    Editor
                                </span>
                            </div>
                            <button>
                                Project Link
                                Copy
                                Offiline
                                Viewing now
                            </button>
                        </li>
                        ))}
                    </ul>
                </div>

                <div className={styles.cookies}>
                    <b className={styles.title}>
                        Cookies
                    </b>
                    <p>
                        Algunas leyes regionales requieren que los propietarios de páginas web informen a los visitantes del seguimiento mediante cookies.
                        Mostrar notificaciones de cookies
                    </p>
                    {/* <Input
                        label="Site Title"
                        // icon={<IconPropDesktop />}
                        value={'eeeee'}
                        onChange={(e) => console.log(e.target.value)}
                        placeholder="Ingrese su nombre"
                    >
                        Site Title
                    </Input> */}
                    <b className={styles.subtitle}>
                        Título
                    </b>
                    Título
                    <b className={styles.subtitle}>
                        Mensaje
                    </b>
                    <p>
                        Nuestro texto predeterminado es solo un ejemplo y debes buscar asesoría jurídica para determinar el texto exacto que debes mostrar en tu sitio.
                    </p>
                    <p>
                        Usamos cookies para analizar el tráfico del sitio web y optimizar tu experiencia en el sitio. Al aceptar nuestro uso de cookies, tus datos se agruparan con los datos de los demás usuarios.
                    </p>
                    <button>
                        Botón Aceptar
                    </button>
                    <p>
                        Puedes cambiar el texto para el botón de Aceptar. Sin embargo, la funcionalidad no cambiará. Cuando los que visiten tu sitio web hagan clic en este botón, estarán aceptando el uso de cookies para hacer un seguimiento del tráfico del sitio web.
                    </p>
                    <button>
                        Aceptar
                    </button>
                    Botón Rechazar
                    <p>
                        Puedes agregar un botón para que tus visitantes rechacen el seguimiento con cookies. Como el seguimiento solo se habilita si tu visitante hace clic en el botón de Aceptar, el botón de Rechazar solo cerrará la ventana emergente de cookies cuando se seleccione.
                    </p>
                    Rechazar
                    <b className={styles.subtitle}>
                        Seguimiento avanzado
                    </b>
                    <p>
                        Permítenos colocar cookies en los navegadores de los visitantes de tu sitio web. Esto nos permite decirte cuántas personas vienen a tu sitio web, optimizar la velocidad con la que se carga tu sitio y enviar métricas relacionadas con integraciones de terceros. Mantén esta configuración activada para ver perspectivas y métricas en tu Panel de control
                    </p>
                </div>

                <div className={styles.domains}>
                    <b className={styles.title}>
                        Dominios
                    </b>
                    <b>
                        Base Domain
                    </b>
                    username.linkeram.com/
                    <ul>
                        <li>
                            /
                            Published
                        </li>
                        <li>
                            /folder
                            Published
                        </li>
                        <li>
                            /blog
                            Publish Now
                        </li>
                        <li>
                            /categories
                            Update
                        </li>
                        <li>
                            /404
                            Update
                        </li>
                    </ul>
                    <button>
                        Conectar Dominio Existente
                    </button>
                    <button>
                        Comprar Dominios
                    </button>
                </div>

                <div className={styles.favicon}>
                    <b className={styles.title}>
                        Favicon
                    </b>
                    <p>
                        Haz que tu página web tenga una apariencia más profesional agregando un icono exclusivo en la pestaña del navegador.
                        Carga una imagen cuadrada(.png, .ico, .jpg), las dimensiones recomendadas: 180px por 180px
                    </p>
                </div>
                <div className={styles.seo}>
                    <b className={styles.title}>
                        SEO
                        PRO
                    </b>
                    <b>
                        Meta Title
                    </b>
                    <p>
                        The title element defines a title in the browser toolbar, provides a title for the page when it is added to favorites and displays a title for the page in search-engine results.
                    </p>
                    Strong Earnest Coyote
                    <b>
                        Meta Description
                    </b>
                    <p>
                        Description of your website is a snippet of up to 160 characters –a tag in HTML– which summarizes a page's content, displayed on search engine results pages.
                    </p>
                    Description of your project
                </div>
                <div className={styles.pixels}>
                    <b className={styles.title}>
                        Pixels
                    </b>
                    <div>
                        Meta Pixel
                        PRO
                    </div>
                    <p>
                        Realiza un seguimiento de los eventos que ocurren en tu perfil de Linkeram utilizando Meta Pixel (anteriormente conocido como Facebook Pixel).
                    </p>
                    <div>
                        Pixel ID
                        Meta Conversions API Access Token
                        El ID o token introducido no es válido.
                        El ID o token introducido no es válido.
                    </div>
                    <div>
                        Puedes encontrar el ID y el token en el Administrador de Anuncios de Facebook.
                    </div>
                    <b>
                        Google Analytics
                    </b>
                    PRO
                    <p>
                        Haz que Google Analytics muestre el tráfico de Linkeram. El parámetro de campaña se establece de forma dinámica a partir del título de cada enlace.
                    </p>
                    <b>
                        Google Measurement ID
                    </b>
                    <b>
                        El ID introducido no es válido.
                    </b>
                    <div>
                        Los parámetros se configurarán para garantizar que Google Analytics muestre todo el tráfico de Linkeram en los datos "Referencias". Siempre puedes configurar parámetros de URL personalizados si lo prefieres.
                    </div>
                </div>

                <div className={styles.publish}>
                    <b>
                        Publish
                    </b>
                    <b>
                        Base Domain
                    </b>
                    <div>
                        username.linkeram.com/
                        See Domains
                    </div>
                    <Select >
                        Status
                        Publico
                    </Select>
                    <div>
                        El proyecto está publico en su dominio.
                    </div>
                    <div>
                        El proyecto está publico en la comunidad de plantillas
                    </div>
                    <div>
                        Publicar Proyecto como Plantilla
                    </div>
                    <div>
                        Publicar en tu Canal de Linkeram
                    </div>

                </div>

                <div className={styles.channel}>
                    <div>

                        Canal
                        JD
                        John Doe
                        @johndoe
                        Tags
                        Publish Now
                        #Tech
                        #love
                        Ver Más Opciones de la Publicación
                    </div>
                    <div>

                        Enviar a
                        Categoría
                        Home
                        Categoría
                        Publicar en la Comunidad de Plantillas
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings