// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabContainer_DDST9 {
    display: flex;
    gap: 10px;
    .tabItem_tHTMl {
        cursor: pointer;
        &.active_rFie3 {
            border-bottom: 2px solid #000;
        }
        .tabIcon_b4etX {
            margin-left: 10px;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/Editor/component/tab.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT;QACI,eAAe;QACf;YACI,6BAA6B;QACjC;QACA;YACI,iBAAiB;QACrB;IACJ;AACJ","sourcesContent":[".tabContainer {\n    display: flex;\n    gap: 10px;\n    .tabItem {\n        cursor: pointer;\n        &.active {\n            border-bottom: 2px solid #000;\n        }\n        .tabIcon {\n            margin-left: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `tabContainer_DDST9`,
	"tabItem": `tabItem_tHTMl`,
	"active": `active_rFie3`,
	"tabIcon": `tabIcon_b4etX`
};
export default ___CSS_LOADER_EXPORT___;
