

import IconCh  from"../flag/flag-ch.png"
import IconEn  from"../flag/flag-en.png"
import IconEs  from"../flag/flag-es.png"
import IconFr  from"../flag/flag-fr.png"
import IconGe  from"../flag/flag-ge.png"
import IconGg  from"../flag/flag-gg.png"
import IconGr  from"../flag/flag-gr.png"
import IconHr  from"../flag/flag-hr.png"
import IconIr  from"../flag/flag-ir.png"
import IconMt  from"../flag/flag-mt.png"
import IconRo  from"../flag/flag-ro.png"
import IconSk  from"../flag/flag-sk.png"
import IconSl  from"../flag/flag-sl.png"
import IconSu  from"../flag/flag-su.png"



export const initialLanguage = [{
    title: 'English',
    icon: IconEn,
},{
    title: 'Français',
    icon: IconFr,
},{
    title: 'Español',
    icon: IconEs,
},{
    title: 'Irish',
    icon: IconEn,
},{
    title: 'Românâ',
    icon: IconEn,
},{
    title: 'Deutsch',
    icon: IconGe,
},{
    title: 'Dansk',
    icon: IconEn,
},{
    title: 'slovenčina',
    icon: IconEn,
},{
    title: 'slovenščina',
    icon: IconEn,
},{
    title: 'eesti',
    icon: IconEn,
},{
    title: 'Malti',
    icon: IconEn,
},{
    title: 'Nederlands',
    icon: IconEn,
},{
    title: 'Polski',
    icon: IconEn,
}]