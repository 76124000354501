import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './drive.module.css'

import ComponentSearch from '../component/search'

import ComponentTab from '../component/tab'
import ComponentExpand from '../component/expand'


import { ReactComponent as IconAdvanced } from "../assets/edit/icon-advanced.svg"



const Drive = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()


    const [icons, setIcons] = useState([{
        title: 'Iconos'
    }, {
        title: 'Flechas'
    }, {
        title: 'Comunicacion'
    }, {
        title: 'Juegos'
    }, {
        title: 'Media'
    }, {
        title: 'Seguridad'
    }, {
        title: 'Sistemas'
    }, {
        title: 'Tiempo'
    }, {
        title: 'Naturaleza'
    }, {
        title: 'Personas'
    }, {
        title: 'Finanzas'
    }, {
        title: 'Educacion'
    }, {
        title: 'Desarrollo'
    }, {
        title: 'Diseño'
    }, {
        title: 'Ecommerce'
    }, {
        title: 'Viajes'
    }, {
        title: 'Oficina'
    }])


    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])


    const [activeTab, setActiveTab] = useState('tab1')


    const tabs = [
        {
            id: 'tab1',
            text: 'Inicio',
            path: 'home',
            icon: <IconAdvanced />
        },
        {
            id: 'tab2',
            text: 'Perfil',
            path: 'profile',
            icon: <IconAdvanced />
        },
        {
            id: 'tab3',
            text: 'Configuración',
            path: 'settings',
            icon: <IconAdvanced />
        }
    ]



    return (
        <div className={styles.drive}>
            <ComponentTab
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={(tab) => setActiveTab(tab.id)}
                showIcon={true}
            />
            <ComponentSearch />
            {activeTab === 'tab1' ? (
                <>
                    <div className={styles.labels}>
                        <button>
                            New upload
                        </button>
                        <button>
                            Recent
                        </button>
                    </div>
                    <div className={styles.upload}>
                        <p>
                            Elige tu archivo y súbelo
                        </p>
                        <span>
                            Arrastra y suelta
                        </span>
                    </div>
                    <div className={styles.bar}>
                        <b>
                        Storage
                        </b>
                        <p>
                        84GB OF 120GB
                        </p>
                        <div className={styles.bar}>
                            <div className={styles.progress} />
                        </div>
                    </div>
                    <div className={styles.labels}>
                        <div>
                            Upgrade Plan
                        </div>
                        <label>
                            10% off
                        </label>
                    </div>
                    <div className={styles.buttons}>
                        <button>
                            icon trash
                            Liberar espacio
                        </button>
                        <button>
                            Tus archivos
                        </button>
                    </div>
                    <ul className={styles.files}>
                        {["", ""].map((item, index) => (
                                <li key={index}>
                                    <div className={styles.icon}>
                                        icon drive
                                    </div>
                                    <div className={styles.file}>
                                        <b>
                                            Scann_158.pdf
                                        </b>
                                        <span>
                                            30mb/74mb
                                        </span>
                                    </div>
                                    <div className={styles.percentage}>
                                        46%
                                    </div>
                                </li>
                        ))}
                    </ul>
                    <ul className={styles.files}>
                        {[""].map((item, index) => (
                            <li key={index}>
                                icon impresora
                                <b>
                                    user-journey
                                </b>
                                <span>
                                    2m ago
                                </span>
                                <label>
                                    45%
                                </label>
                            </li>
                        ))}
                    </ul>
                </>
            ) : activeTab == 'tab2' ? (
                
                <ul className={styles.images}>
                    <label>
                        Unsplash
                    </label>
                    {["", ""].map((item, index) => (
                        <li className={styles.image}>
                            <div>
                                image #1
                            </div>
                            <div>
                                image #2
                            </div>
                        </li>
                    ))}
                </ul>
            ) : activeTab == 'tab3' ? (
                <>
                    {icons.map((item, index) => (
                        <ComponentExpand 
                        key={index}
                        icon={item.icon}
                        title={item.title}
                        >
                        
                        <div className={styles.icons}>
                            {/* <div>
                                {item.icon}
                            </div>
                            <p>
                                {item.title}
                            </p> */}
                            <div>
                                <svg fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8 10 4 4 4-4" />
                                </svg>
                            </div>
                            <ul>
                                <li>
                                    f1
                                </li>
                                <li>
                                    f2
                                </li>
                                <li>
                                    f3
                                </li>
                                <li>
                                    f4
                                </li>
                                <li>
                                    f5
                                </li>
                                <li>
                                    f6
                                </li>
                                <li>
                                    f7
                                </li>
                                <li>
                                    f8
                                </li>
                            </ul>
                        </div>
                        </ComponentExpand>
                    ))}
                </>
            ) : null}
        </div>
    )
}

export default Drive
