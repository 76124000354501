import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './form.module.css'

import ComponentExpand from '../component/expand'


const Form = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.form}>
            <ComponentExpand>
                <div>
                    Image
                </div>
            </ComponentExpand>

            Embede Layour

            Popup Loyout

            Title

            Exclusive Updated

            0/35 characters

            Form name will help you identify the...


            Subtitle (Optional)

            Receive updated and exclusive content

            0/75 caracteres

            Se ha creado una página de referencia


            https://username.linkeram.com


            Ver respuestas en Google Drive

            Enviar información de contacto a

            Seleccionar lista de audencia


            Image

            Subir image

            .jpg

            Data capture

            Name

            Short Answer text field


            Enter Email

            Email address

            Enter phone number

            Phone number

            Business name
            Short Answer text field

            Message

            Paragraph text field

            Make a selection

            Multiple choice selection

            Option1
            Option 2

            Add a option

            Single choice selection

            Option 1

            Add a option

            Select a country

            Country Dropdown


            Select a Date

            Date Selection

            Constent & Permissions

            Default Unchecked


            Default Checked

            Plain Text

            Add a field

            Button text


            Submit

            Color
            icon




            --------------------

            Contact Form

            Name
            Please fill this out

            Enter email

            Enter your email address

            Telefono


            +00


            Empresa

            Please fill this out 2


            Message

            Please fill this out

            Make a selection

            Option 1

            Option 2


            Make a Selection


            Option 1

            Option 2


            Select a country

            Select a date

            Consent & Permisions

            Option 1


            Submit

            Cancel


        </div>
    )
}

export default Form
















const Viewer = () => {
    return (
        <div>

            <div>
                <div>

                </div>
                <div>
                    <b>
                        Form Banner
                    </b>
                    <p>
                        Subtitle
                    </p>
                    <button>
                        Staert form
                    </button>
                </div>
            </div>



            <div>
                <div>

                </div>
                <div>
                    <b>
                        Form Banner
                    </b>
                    <p>
                        Subtitle
                    </p>
                </div>
            </div>


            <div>
                <div>
                    Contact Form
                </div>
                <div>
                    input file 

                    Fill this out

                    Enter email
                    Enter your email address

                    Teléfono 
                    000

                    Empresa
                    Please fill this out 2

                    Message
                    Please fill this out

                    Make a selection
                    Option 1
                    Option 2

                    Select a country
                    Select a date

                    Consent & Permisions
                    Option 1

                    Submit
                    Cancel
                </div>
            </div>
        </div>
    )
}