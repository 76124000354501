import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './image.module.css'

import ComponentExpand from '../component/expand'

const Image = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.image}>
            <ComponentExpand>
                <div>
                    Image
                </div>
            </ComponentExpand>
            <div>


                Image
            </div>

            <div>

                Subir JPG, PNG, SVG
                2.jpg
            </div>

            <div>
                Static
                Pop-up
                Link
            </div>
            <div>
                URL
                https://
            </div>


            <Viewer />
        </div>
    )
}

export default Image


const Viewer = () => {
    return (
        <div>

        <div>
           <div></div>
           <div>
            <b>
                Title Layout
            </b>
            <p>
                This is a Description
            </p>
           </div>
        </div>
        <div>
            <div>

            </div>
            <div>
                <b>
                    Title Layout
                </b>
                <p>
                    This is a Description
                </p>
            </div>
        </div>
        </div>
    )
}