import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'

import styles from './search.module.css'

const SearchComponent = ({
    onSearch,          // Función para buscar
    onSelect,          // Función cuando se selecciona un item
    placeholder = "Buscar...",
    showList = true,   // Control de visibilidad de la lista
    delay = 700       // Delay del debounce
}) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [results, setResults] = useState([])
    const [timeoutId, setTimeoutId] = useState(null)

    const handleSearch = (value) => {
        setSearchTerm(value)
        
        // Limpia el timeout anterior
        if (timeoutId) clearTimeout(timeoutId)
        
        // Crea un nuevo timeout
        const newTimeoutId = setTimeout(async () => {
            const searchResults = await onSearch(value)
            setResults(searchResults)
        }, delay)
        
        setTimeoutId(newTimeoutId)
    }

    const handleSelect = (item) => {
        setSearchTerm(item.title || item.name || item)
        setResults([])
        onSelect(item)
    }

    return (
        <div className={styles.searchContainer}>
            <div className={styles.searchInputWrapper}>
                <FaSearch className={styles.searchIcon} />
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder={placeholder}
                    className={styles.searchInput}
                />
            </div>
            
            {showList && results.length > 0 && (
                <ul className={styles.searchResults}>
                    {results.map((item, index) => (
                        <li 
                            key={index}
                            onClick={() => handleSelect(item)}
                            className={styles.searchResultItem}
                        >
                            {item.title || item.name || item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default SearchComponent




/*
// Componente padre
const ParentComponent = () => {
    const [selectedItem, setSelectedItem] = useState(null)
    
    // Función de búsqueda que retorna resultados
    const handleSearch = async (searchTerm) => {
        // Aquí irían tus llamadas a la API o lógica de búsqueda
        const response = await fetch(`/api/search?q=${searchTerm}`)
        const data = await response.json()
        return data
    }
    
    // Función que se ejecuta cuando se selecciona un item
    const handleSelect = (item) => {
        setSelectedItem(item)
        // Aquí puedes agregar más lógica
    }
    
    return (
        <SearchComponent
            onSearch={handleSearch}
            onSelect={handleSelect}
            placeholder="Buscar elementos..."
            showList={true}
            delay={700}
        />
    )
}
    */
