import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './www.module.css'

import Search from '../component/search'

const Www = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])



    // ------------------------------



    const [selectedItem, setSelectedItem] = useState(null)

    // Función de búsqueda que retorna resultados
    const handleSearch = async (searchTerm) => {
        // Aquí irían tus llamadas a la API o lógica de búsqueda
        const response = await fetch(`/api/search?q=${searchTerm}`)
        const data = await response.json()
        return data
    }

    // Función que se ejecuta cuando se selecciona un item
    const handleSelect = (item) => {
        setSelectedItem(item)
        // Aquí puedes agregar más lógica
    }


    return (
        <div className={styles.www}>

            <Search
                onSearch={handleSearch}
                onSelect={handleSelect}
                placeholder="Buscar elementos..."
                showList={true}
                delay={700}
            />


            icon search
            icon domain

            Domains
            <ul>
                <li>
                    icon home
                </li>
                <li>
                Home
                </li>
                <li>
                /new-folder
                </li>
                <li>
                /blog
                </li>
                <li>
                    /categories
                </li>
                <li>
                    /404
                </li>
            </ul>


            components

            Elements


            < ul >
                <li>
                    New Page
                </li>
                <li>
                    New Page Folder
                </li>
                <li>
                    New CMS Page
                </li>
                <li>
                    New 404 Page
                </li>
                <li>
                    Generat with AI
                </li>
                <li>
                    New Component
                </li>
                <li>
                    New Space
                </li>
            </ul >


            New page
            Name
            / page - name

            icon short Short URL
            username.linkeram.com /
            page - name

            El URL introducido no es válido
            Dejar vacío para generar el enlace aleatoriamente.

            new page
            new page folder
            generate page with ai


        </div >
    )
}

export default Www
