import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './interactive.module.css'



const Interactive = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    const [tag, setTag] = useState('event')

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.edit}>


        </div>
    )
}

export default Interactive
