import React from "react";
import styles from "./homeScreen2.module.css";
import CreateUser from "../component/CreateUser/CreateUser";

export default function HomeScreen2() {
    return (
        <div className={styles.homeScreen2}>
            {/* Sección izquierda */}
            <div className={styles.imageContainer}>
                <img 
                    style={{ width: "100%", height: '100%', objectFit: "cover" }} 
                    alt="foto" 
                    src="/fondoLinkeram.png"
                />
            </div>
            
            {/* Sección derecha */}
            <div className={styles.containerLoguin}>
                <CreateUser />
            </div>
        </div>
    );
}
