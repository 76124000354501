import React, { Suspense, lazy, useEffect, useState } from 'react'
import {
	BrowserRouter,
	Routes,
	Route,
	useParams,
	Navigate,
	useNavigate,
} from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import { Provider } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { createRoot } from 'react-dom/client'

import i18n from './i18n'

import store from './views/app/v1-1/utils/store'
import generateColors from './views/app/v1-1/utils/colors'

const VERSION = process.env.REACT_APP_VERSION

import SignTest from './save/sign.jsx'

const App = lazy(() => import('@app/index.jsx'))

import LoadingScreen from './load'

import Test from './test'

import DesktopNotAvailable from './views/app/v1-1/DesktopNotAvailable'

import Viewer from './views/app/v1-1/pages/Drive/components/viewer'
import Editor from './views/app/v1-1/pages/Editor'

import Home from './views/web/components/Home'
import HomeUtilize from './views/web/components/Utilize'
import HomeReleaseNotes from './views/web/components/ReleaseNotes'

import HomePrivacy from './views/web/components/Privacy'
import HomePricing from './views/web/components/Pricing'
import HomeContact from './views/web/components/Contact'
import HomeRoadMap from './views/web/components/RoadMap'
import HomeBlog from './views/web/components/Blog'
import HomeBlogDetails from './views/web/components/Blog/BlogDetails'
import HomeCloud from './views/web/components/Cloud'

import GameHome from './test/game'

import HomeLogIn from './views/web/components/SignIn'
import HomeSignUp from './views/web/components/SignUp'

import Login from './views/auth/login'
import Register from './views/auth/register'
import RecoverPassword from './views/auth/recover-password'

import HowToUse from './views/auth/how-to-use/index.jsx'

import Locales from './locales'
import Landing from './views/web/landing/home'
import Sign from './admin/sign'
import Funnel from './views/web/funnel'
import Payment from './admin/payment'
import FormIframe from './admin/form/iframe'

import { verify } from './views/app/v1-1/actions/iam'
import { apiUrl } from '@src/apiBackend'

import './global.css'

import Test1 from './test-delete-component'

import Linkeram from './views/app/v1-1/pages/LinkeRam'
import Ram from './views/app/v1-1/pages/Ram'


import Calculator from './views/app/v1-1/pages/Desktop/settings/calculator'

const ProtectedRouteVersion = ({
	license,
	element,
	setIsAuth,
	currentLanguage,
}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { lng, version } = useParams()
	const [isElement, setIsElement] = useState(null)

	useEffect(() => {
		const fetchData = async () => {
			var token = localStorage.getItem('token')

			if (!token || token == 'undefined') {
				navigate(`/${currentLanguage}`)
				setIsAuth(false)
				setIsElement(null)
				return false
			}

			var res = await dispatch(verify({}))

			if (res.payload == 409) {
				setIsAuth(false)
				setIsElement(<DesktopNotAvailable value={'409'} />)
				return false
			}

			if (res.payload > 500 && res.payload <= 599) {
				navigate(`/${currentLanguage}/login`)
				setIsAuth(false)
				setIsElement(null)
				window.location.reload()
				return false
			}

			if (res.payload == 500) {
				setIsAuth(true)
				setIsElement(element)
				navigate(`/${currentLanguage}/${version}/account?status=500`)
				return false
			}

			if (res.payload?.user?.name) {
				document.title = 'AY > ' + res.payload.user.name
			} else {
				document.title = 'AythenDB'
			}

			if (res.payload?.user?.id) {
				const faviconLink = document.createElement('link')
				faviconLink.rel = 'icon'
				faviconLink.href = `${apiUrl}/service/v1/iam/account/${res.payload?.user?.id}`
				faviconLink.type = 'image/x-icon'

				const head = document.head || document.getElementsByTagName('head')[0]

				const existingFavicon = document.querySelector('link[rel="icon"]')
				if (existingFavicon) {
					head.removeChild(existingFavicon)
				}

				const tempImg = new Image()
				tempImg.src = faviconLink.href

				tempImg.onload = () => {
					if (existingFavicon) {
						head.appendChild(faviconLink)
					}
				}
			}

			if (version !== license) {
				navigate(`/${currentLanguage}/${license}`)
			}

			setIsAuth(true)
			setIsElement(element)
		}

		fetchData()
	}, [element])

	return isElement
}

const Layout = () => {
	const { pathname } = window.location

	const languageFromPath = pathname.split('/')[1]

	const supportedLanguages = [
		'en',
		'es',
		'fr',
		'de',
		'it',
		'ru',
		'pt',
		'nl',
		'sv',
	]

	const defaultLanguage = 'es'
	const currentLanguage =
		languageFromPath && supportedLanguages.includes(languageFromPath)
			? languageFromPath
			: defaultLanguage

	const [colorsLight, setColorsLight] = useState([])
	const [colorsDark, setColorsDark] = useState([])

	useEffect(() => {
		i18n.changeLanguage(currentLanguage)
	}, [currentLanguage])

	const [isAuth, setIsAuth] = useState(false)

	useEffect(() => {
		const color = localStorage.getItem('themeColor')
		let colors
		if (color) {
			colors = generateColors(color)
			setColorsLight(colors.light)
			setColorsDark(colors.dark)
		}
	}, [])

	function RedirectToDefaultVersion() {
		const { lng } = useParams()
		const defaultVersion = 'v1-1'

		return <Navigate to={`/${lng}/${defaultVersion}`} replace />
	}

	const [isIframe, setIsIframe] = useState(false)

	useEffect(() => {
		try {
			const { pathname } = window.location
			if (pathname == '/proxy') {
				setIsIframe(true)

			}
		} catch (e) {
			setIsIframe(true)
		}
	}, [])


	return (
		<div>
			{colorsLight.length > 0 && (
				<style>
					{`
          :root{
            ${colorsLight.map((color, index) => `--color-primary-${index}: ${color};`).join('\n')}
          }
          body.dark-mode{
            ${colorsDark.map((color, index) => `--color-primary-${index}: ${color};`).join('\n')}
          }
        `}
				</style>
			)}

			<DndProvider backend={HTML5Backend}>
				<Provider store={store}>
					<BrowserRouter>
						{isIframe ? (
							<Routes>
								<Route index path='*' element={<DesktopNotAvailable />} />
							</Routes>
						) : (
							<Routes>
								<Route path='/:lng/sign-test' element={<SignTest />} />

								<Route
									path='/'
									element={
										<ProtectedRouteVersion
											license={VERSION}
											element={<App isAuth={isAuth} setIsAuth={setIsAuth} />}
											setIsAuth={setIsAuth}
											currentLanguage={currentLanguage}
										/>
									}
								/>

								<Route path='/:lng/*' element={<RedirectToDefaultVersion />} />

								<Route
									path='/:lng/:version/*'
									element={
										<Suspense fallback={<LoadingScreen />}>
											<ProtectedRouteVersion
												license={VERSION}
												element={<App isAuth={isAuth} setIsAuth={setIsAuth} />}
												setIsAuth={setIsAuth}
												currentLanguage={currentLanguage}
											/>
										</Suspense>
									}
								/>

								<Route path='/test' element={<Test />} />
								<Route path='/test1' element={<Test1 />} />


								<Route path='/calculator' element={<Calculator />} />

								<Route path='/:lng/how-to-use' element={<HowToUse />} />
								<Route path='/:lng/how-to-use/:tag' element={<HowToUse />} />

								<Route
									path='/:lng/login'
									element={
										isAuth ? (
											<App isAuth={isAuth} setIsAuth={setIsAuth} />
										) : (
											<Login />
										)
									}
								/>
								<Route
									path='/:lng/register'
									element={
										isAuth ? (
											<App isAuth={isAuth} setIsAuth={setIsAuth} />
										) : (
											<Register />
										)
									}
								/>
								<Route
									path='/:lng/recover-password'
									element={
										isAuth ? (
											<App isAuth={isAuth} setIsAuth={setIsAuth} />
										) : (
											<RecoverPassword />
										)
									}
								/>
								<Route path='/ram' element={<Ram />} />
								<Route path='/editor' element={<Editor />} />
								<Route path='/editor/:tag' element={<Editor />} />
								<Route path='/editor/:tag/:subTag' element={<Editor />} />

								<Route path='/linkeram' element={<Linkeram />} />

								<Route path='/viewer' element={<Viewer />} />
								<Route path='/:lng/sign' element={<Sign />} />
								<Route path='/:lng/funnel' element={<Funnel />} />
								<Route path='/:lng/pay/:id' element={<Payment />} />
								<Route path='/:lng/form/view/:id' element={<FormIframe />} />

								<Route path='/game' element={<GameHome />} />
								<Route path='/locales' element={<Locales />} />

								<Route path='/:lng' element={<Home />} />
								<Route path='/:lng/privacy' element={<HomePrivacy />} />
								<Route path='/:lng/utilize' element={<HomeUtilize />} />
								<Route
									path='/:lng/utilize/:tagUtilize'
									element={<HomeUtilize />}
								/>
								<Route path='/:lng/release' element={<HomeReleaseNotes />} />
								<Route path='/:lng/pricing' element={<HomePricing />} />
								<Route path='/:lng/contact' element={<HomeContact />} />
								<Route path='/:lng/roadmap' element={<HomeRoadMap />} />
								<Route path='/:lng/blog' element={<HomeBlog />} />
								<Route
									path='/:lng/blog-details'
									element={<HomeBlogDetails />}
								/>
								<Route path='/:lng/sign' element={<HomeLogIn />} />
								<Route path='/:lng/signup' element={<HomeSignUp />} />
								<Route path='/:lng/cloud' element={<HomeCloud />} />
								<Route path='/:lng/features' element={<Landing />} />

							
							</Routes>
						)}
					</BrowserRouter>
				</Provider>
			</DndProvider>
		</div>
	)
}

const container = document.getElementById('app')
if (container) {
	const root = createRoot(container)
	root.render(<Layout />)
} else {
	console.log("Error No se encontró el contenedor con id 'root'.")
}
