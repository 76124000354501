import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

// Ejemplo de HTML template como string
const htmlTemplate = `
  <div class="custom-addon">
    <img src="{imageUrl}" alt="addon-logo" />
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
`

const DragItem = ({ 
    width = '1/2', 
    imageUrl = '', 
    title = '', 
    description = ''
}) => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    const handleDragStart = (e) => {
        setIsDragging(true)
        // Crear efecto fantasma personalizado
        const ghostElement = document.createElement('div')
        ghostElement.classList.add('drag-ghost')
        ghostElement.innerHTML = htmlTemplate
            .replace('{imageUrl}', imageUrl)
            .replace('{title}', title)
            .replace('{description}', description)
        
        document.body.appendChild(ghostElement)
        e.dataTransfer.setDragImage(ghostElement, 0, 0)
        e.dataTransfer.setData('text/plain', JSON.stringify({
            type: 'addon',
            template: htmlTemplate,
            data: { imageUrl, title, description }
        }))

        // Limpiar el elemento fantasma después
        setTimeout(() => {
            document.body.removeChild(ghostElement)
        }, 0)
    }

    const handleDragEnd = () => {
        setIsDragging(false)
    }

    return (
        <div 
            className={`preset-container w-${width}`}
            draggable="true"
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <div className={`preset-content ${isDragging ? 'dragging' : ''}`}>
                <div className="preset-logo">
                    <img src={imageUrl} alt="addon logo" />
                </div>
                <div className="preset-info">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default DragItem


/*

const [addons, setAddons] = useState([])

// Ejemplo de manejo del drop
const handleDrop = (e) => {
    e.preventDefault()
    const data = JSON.parse(e.dataTransfer.getData('text/plain'))
    if (data.type === 'addon') {
        setAddons(prev => [...prev, {
            id: Date.now(),
            ...data.data
        }])
    }
}
    
<Presets 
    width="1/2"
    imageUrl="/path/to/logo.png"
    title="Mi Addon"
    description="Descripción del addon"
/>
*/