// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_w9Yqs{
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;

}

.subContainerLoguin_XifTP {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}


.text_EuU0q {
    color: black; /* Color de texto principal en negro */
    font-size: 11px; /* Ajusta el tamaño del texto según prefieras */
    margin: 10px 0;
    text-align: center;
}

.violetText_oFGzK {
    color: #622FAE; /* Color violeta para el texto en strong */
}

.inputLoguin_s1neZ {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: rgb(196, 196, 196);
    border-style: solid;
    border-radius: 6px;
    width: 100%;
    height: 40px;
    background-color: #FAFAFA;
    font-size: 10px;
    padding: 0% 4%;
    outline: none; 
    color: grey;
}`, "",{"version":3,"sources":["webpack://./src/views/app/v1-1/pages/LinkeRam/component/CreateUser/createUser.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;;AAE3B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;;;AAGA;IACI,YAAY,EAAE,sCAAsC;IACpD,eAAe,EAAE,+CAA+C;IAChE,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc,EAAE,0CAA0C;AAC9D;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,gCAAgC;IAChC,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,cAAc;IACd,aAAa;IACb,WAAW;AACf","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    width: 45%;\n    height: 100%;\n    background-color: white;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.subContainerLoguin {\n    width: 60%;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    align-items: center;\n}\n\n\n.text {\n    color: black; /* Color de texto principal en negro */\n    font-size: 11px; /* Ajusta el tamaño del texto según prefieras */\n    margin: 10px 0;\n    text-align: center;\n}\n\n.violetText {\n    color: #622FAE; /* Color violeta para el texto en strong */\n}\n\n.inputLoguin {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-width: 1px;\n    border-color: rgb(196, 196, 196);\n    border-style: solid;\n    border-radius: 6px;\n    width: 100%;\n    height: 40px;\n    background-color: #FAFAFA;\n    font-size: 10px;\n    padding: 0% 4%;\n    outline: none; \n    color: grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_w9Yqs`,
	"subContainerLoguin": `subContainerLoguin_XifTP`,
	"text": `text_EuU0q`,
	"violetText": `violetText_oFGzK`,
	"inputLoguin": `inputLoguin_s1neZ`
};
export default ___CSS_LOADER_EXPORT___;
