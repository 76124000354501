import { useContext, useEffect } from 'react'
import { EditorContext } from '../index'
import { useParams } from 'react-router-dom'

import styles from './link-button.module.css'

const LinkButton = () => {
    const { language, setLanguage } = useContext(EditorContext)
    const params = useParams()

    useEffect(() => {
        if (params.language) {
            setLanguage(params.language)
        }
    }, [params.language])

    return (
        <div className={styles.linkButton}>
   Link button

   icon 1
   icon 2
   icon 3

   URL
   https://

   or

   Page Button

   /home
   icon array

   Thumbnail
   (Optional)

   Subir JPG, PNG, SVG

   .jpg





   -----


   Descargable 

   Subir archivo 

   .jpg






   ---


   Copy clipboard 

        icon

        icon clipboard


        Content

        When clicked, copy the URL to the clipboard
   --

        </div>
    )
}

export default LinkButton




const Viewer = () => {
    return (
        <div>

            <div>
                <div></div>
                <div>
                    <b>
                        Classic Button
                    </b>
                    <p>
                        This is a classic Button
                    </p>
                </div>
            </div>

            <div>
                <div></div>
                <div>
                    <b>
                        Featured Button
                    </b>
                    <p>
                        This is a Featured Button
                    </p>
                </div>
            </div>

            <div>
                <div></div>
                <div>
                    <b>
                        Card Button
                    </b>
                    <p>
                        This is a Card Layout
                    </p>
                </div>
            </div>
        </div>
    )
}