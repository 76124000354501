// RememberMeAndForgotPassword.js
import React from 'react';
import styles from "./loguin.module.css";

const RememberMeAndForgotPassword = () => {
    return (
        <div className={styles.containerButtonInputs}>
            <div className={styles.leftContainerButtonInputs}>
                <label className={styles.checkbox}>
                    <input type="checkbox" className={styles.checkboxInput} />
                    <span className={styles.checkboxBox}></span>
                    Recuérdame
                </label>
            </div>
            <div className={styles.rightContainerButtonInputs}>
                olvidaste la contraseña?
            </div>
        </div>
    );
};

export default RememberMeAndForgotPassword;
