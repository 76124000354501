// Loguin.js
import React from "react";
import styles from "./loguin.module.css";
import LogoImage from "./LogoImage";
import NormalInput from "./NormalInput";
import PasswordInput from "./PasswordInput";
import RememberMeAndForgotPassword from "./RememberMeAndForgotPassword";
import GoButton from "./GoButton";
import SocialButton from "./SocialButton";
import RegistrationLink from "./RegistrationLink";
import SecurityInfo from "../homeScreen3/SecurityInfo";

export default function Loguin() {
    return (
        <div className={styles.containerLoguin}>
            <div className={styles.subContainerLoguin}>
                {/* Componente para la imagen */}
                <LogoImage src="/formLinkeram.png" alt="ojear" />

                {/* Input de email */}
                <NormalInput className={styles.inputLoguin} placeholder={"Email"} />

                {/* Input de contraseña */}
                <PasswordInput className={styles.inputLoguin} placeholder={"Contraseña"} />

                {/* Recuerdame y Olvidaste la contraseña */}
                <RememberMeAndForgotPassword />

                {/* Botón de "Entrar" */}
                <GoButton label="Entrar" />

                {/* Botón de Google */}
                <SocialButton src="/google.png" alt="google" />

                {/* Enlace para registrarse */}
                <RegistrationLink />

                {/* Componente de seguridad */}
                <SecurityInfo />
            </div>
        </div>
    );
}
